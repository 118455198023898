import flatten from "lodash/flatten";
import {
  DEFAULT_APP_PRELOAD_DURATION,
  DEFAULT_DURATIONS,
  DEFAULT_FILE_PRELOAD_DURATION,
  DEFAULT_LINK_PRELOAD_DURATION,
  DEFAULT_SITE_PRELOAD_DURATION,
  DEFAULT_SIZE_TYPE_DOCUMENT,
  DEFAULT_SIZE_TYPE_FALLBACK,
  DEFAULT_SIZE_TYPE_IMAGE,
  DEFAULT_SIZE_TYPE_VIDEO,
} from "../../constants";
import { ContentSizeType, LeafContentType } from "../../types/content";
import { fileMediaType, getDocumentPages } from "../../utils";
import { isContentItemParent } from "../../utils/contentItemTypeGuards";
import { notUndefinedOrNull } from "../../utils/helpers";
import {
  DefaultDurations,
  GqlContentListItem,
  GqlContentRef,
  GqlZoneItemSizeType,
} from "../graphqlTypes";
import {
  ChannelZoneContentItem,
  ContentItemLeaf,
  ContentItemPlaylist,
  ContentListIdParseReport,
  LocalTimelineIdParseReport,
} from "./types";
import { FileFragment } from "../../queries";
import { NormalizedAppInstanceFragment } from "../apps/types";

const channelZoneContentListIdDivider = "::zone::";

/**
 * Content Lists do not have GraphQL IDs, so we generate local IDs following a consistent pattern
 * depending on the type of list it is.
 */
export const makeContentListIdForZone = (
  layoutId: string | null, // null => no layout (splash screen)
  zoneId: string
): string => {
  return `${layoutId}${channelZoneContentListIdDivider}${zoneId}`;
};

/**
 * Generates a content list id for a playlist.
 */
export const makeContentListIdForPlaylist = (playlistId: string): string => {
  // content list id for a playlist matches playlist id exactly
  return playlistId;
};

export const parseContentListId = (
  contentListId: string
): ContentListIdParseReport => {
  const type = isPlaylistContentListId(contentListId)
    ? "playlist"
    : "channel-zone";
  if (type === "playlist") {
    return {
      type,
      playlistId: contentListId,
    };
  } else {
    if (contentListId.indexOf(channelZoneContentListIdDivider) === -1) {
      throw new Error("Invalid content list id input: " + contentListId);
    }
    const [layoutId, zoneId] = contentListId.split(
      channelZoneContentListIdDivider
    );
    return {
      type,
      layoutId,
      zoneId,
    };
  }
};

/**
 * Generates timeline id for a content list.
 * Timelines are generated from content lists so each timeline has a corresponding content list.
 */
export const makeTimelineIdForContentList = (contentListId: string): string => {
  // timeline id matches content list id
  return contentListId;
};

/**
 * Returns original input info that was used to generate timeline id
 */
export const parseLocalTimelineId = (
  timelineId: string
): LocalTimelineIdParseReport => {
  return {
    sourceContentListId: timelineId,
  };
};

/**
 * Returns if a given content list id belongs to a particular channel
 */
export const doesContentListBelongToChannel = (
  channel: { layoutByChannel: string },
  contentListId: string
): boolean => {
  return contentListId.startsWith(`${channel.layoutByChannel}::zone::`);
};

/**
 * Figures out if a content list id relates to a former playlist entity
 */
export const isPlaylistContentListId = (contentListId: string): boolean => {
  // just checking if it's a non modified id string
  return contentListId.length === 36;
};

/**
 * Generates composite id for a zone related timeline
 */
export const makeTimelineIdForZone = (
  layoutId: string,
  zoneId: string
): string => {
  // currently it makes sense for contentList ids and timeline ids to be exactly the same
  return makeContentListIdForZone(layoutId, zoneId);
};

/**
 * Replace references to a nested list (e.g. a playlist) with
 * the contents of that list instead.
 */
export const flattenNestedContentItems = (
  items: ChannelZoneContentItem[],
  contentItemLists: {
    [id: string]: ContentItemLeaf[];
  },
  files: { [id: string]: FileFragment }
): ContentItemLeaf[] => {
  const withNestedItemsArray = items
    .map((item) => {
      if (!isContentItemParent(item)) {
        return item as ContentItemLeaf;
      }

      if (!contentItemLists[item.id]) {
        return undefined;
      }

      return contentItemLists[item.id].map((itemToFlatten) => ({
        ...applyInheritedPropsToContentItem(item, itemToFlatten, files),
      }));
    })
    .filter(notUndefinedOrNull);

  return flatten(withNestedItemsArray);
};

const applyInheritedPropsToContentItem = (
  parentItem: ContentItemPlaylist,
  item: ContentItemLeaf,
  files: { [id: string]: FileFragment }
): ContentItemLeaf => {
  return {
    ...item,
    rules: item.rules || parentItem.rules,
    parent: {
      id: parentItem.id,
      type: parentItem.type,
      listId: parentItem.listId,
    },
    sizeType: getSizeTypeFromInheritedProps(parentItem, item, files),
    transition: parentItem.transition,
  };
};

// Takes the sizeType from the channel and applies it to the playlist contentItems.
const getSizeTypeFromInheritedProps = (
  parentItem: ContentItemPlaylist,
  item: ContentItemLeaf,
  files: { [id: string]: FileFragment }
): ContentSizeType => {
  let sizeType = item.sizeType;

  if (item.type === "file") {
    const file = files[item.id];
    if (file && file.mimetype) {
      const fileType = fileMediaType(file.mimetype);
      if (fileType !== "audio") {
        sizeType = parentItem.defaultSizeTypes?.[fileType] || item.sizeType;
      }
    }
  }
  return sizeType;
};

/**
 * Calculate the length of time each individual item will play for *before* adding to store.
 * This way, the components that display the ContentList don't need knowledge of any other part
 * of the system (e.g. doesn't care if the list is in a channel or not)
 */
export const getItemDuration = (
  item: GqlContentListItem,
  entity: FileFragment | NormalizedAppInstanceFragment | undefined
): number | undefined => {
  if (entity) {
    if ("mimetype" in entity && entity.mimetype) {
      // File entity
      if (
        fileMediaType(entity.mimetype) === "document" &&
        item.content.props?.duration
      ) {
        const pages = getDocumentPages(entity);
        return item.content.props.duration * pages.length;
      } else if (fileMediaType(entity.mimetype) === "video") {
        const fileOutput = entity.fileOutputsByFileId.nodes.find((output) =>
          output.mimetype?.startsWith("video")
        );
        return fileOutput?.metadata?.duration;
      } else if (fileMediaType(entity.mimetype) === "audio") {
        const fileOutput = entity.fileOutputsByFileId.nodes.find((output) =>
          output.mimetype?.startsWith("audio")
        );
        return fileOutput?.metadata?.duration;
      }
    } else if ("appVersionByAppInstanceId" in entity) {
      // App entity
      if (entity.config?.appInstanceDurationInSeconds) {
        return entity.config.appInstanceDurationInSeconds * 1000;
      }
    }
  }

  if (item.content.props?.duration) {
    return item.content.props?.duration;
  }

  return undefined;
};

/**
 * Returns a set of default durations for a content item loaded from graphql
 * @param contentRef
 * @param fallbackDefaultDurations - default durations that are supposed to be inherited
 */
export const getDefaultDurationsForItem = (
  contentRef: GqlContentRef,
  fallbackDefaultDurations: DefaultDurations | undefined
): DefaultDurations => {
  return (
    contentRef.props?.default_durations ||
    fallbackDefaultDurations ||
    DEFAULT_DURATIONS
  );
};

/**
 * How long in advance should an item be preloaded?
 */
export const getPreloadDuration = (
  item: GqlContentListItem
): number | undefined => {
  const itemType = item.content._ref.type;

  // TODO
  // 1 - Read specific value on the item
  // e.g. different apps can have different preload requirements

  // 2 - Use the defaults by type.
  switch (itemType) {
    case "app":
      return DEFAULT_APP_PRELOAD_DURATION;
    case "file":
      return DEFAULT_FILE_PRELOAD_DURATION;
    case "link":
      return DEFAULT_LINK_PRELOAD_DURATION;
    case "site":
      return DEFAULT_SITE_PRELOAD_DURATION;
    case "channel":
    case "playlist":
      // i.e. don't preload these items
      return undefined;
  }
};

export const getItemSizeType = (
  contentType: LeafContentType,
  sizeTypes: GqlZoneItemSizeType | undefined,
  mimetype: string | undefined | null
): ContentSizeType => {
  if (!mimetype || !sizeTypes || contentType !== "file") {
    return DEFAULT_SIZE_TYPE_FALLBACK;
  }

  const mediaType = mimetype ? fileMediaType(mimetype) : undefined;

  if (mediaType === "image") {
    return sizeTypes.image || DEFAULT_SIZE_TYPE_IMAGE;
  } else if (mediaType === "video") {
    return sizeTypes.video || DEFAULT_SIZE_TYPE_VIDEO;
  } else {
    return sizeTypes.document || DEFAULT_SIZE_TYPE_DOCUMENT;
  }
};

/**
 * Returns availability setting props if an item is a file
 * @param item
 * @param files - file entities available in the store
 */
export const getItemAvailability = (
  item: GqlContentListItem,
  files: { [id: string]: FileFragment }
): { availableAt: string | null; expireAt: string | null } => {
  if (item.content._ref.type === "file") {
    const fileEntity = files[item.content._ref.id];
    if (fileEntity) {
      return {
        availableAt: fileEntity.availableAt,
        expireAt: fileEntity.expireAt,
      };
    }
  }

  return {
    availableAt: null,
    expireAt: null,
  };
};
