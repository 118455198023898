import React, { FunctionComponent, useEffect } from "react";
import { useFetchSecureMediaPolicy } from "@screencloud/studio-media-client";
import { useDispatch, useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { setConfig } from "../../../../store/config/actions";
import { Logger } from "../../../../logger/logger";
import { ignoreToCacheTheseParamsOnOurNativePlayers } from "../../../../utils/helpers";

const log = new Logger("SecureMediaPolicyContainer");

export interface SecureMediaPolicyContainerProps {
  mediaServiceEndpoint: string;
}

export const SecureMediaPolicyContainer: FunctionComponent<SecureMediaPolicyContainerProps> = (
  props
) => {
  const { mediaServiceEndpoint } = props;
  const dispatch = useDispatch();

  const token = useSelector<PlayerState, string>(
    (state) => state.config.graphqlToken
  );
  const deviceTimeOffset = useSelector<PlayerState, number>(
    (state) => state.config.timeOffset
  );

  const { policy, error } = useFetchSecureMediaPolicy(
    {
      mediaServiceEndpoint: `${mediaServiceEndpoint}/auth`,
      studioAuthToken: token,
      maxRetryTimeMs: 10000,
      deviceTimeOffsetMs: deviceTimeOffset,
    },
    {
      fetchApi: fetch,
    }
  );

  useEffect(() => {
    if (typeof policy === "string") {
      dispatch(
        setConfig({
          secureMediaPolicy: ignoreToCacheTheseParamsOnOurNativePlayers(policy),
        })
      );
    }
  }, [dispatch, policy]);

  useEffect(() => {
    if (error !== undefined) {
      log.error("Can't fetch secure media policy. Error:" + error.message);
    }
  }, [error]);

  return <></>;
};
