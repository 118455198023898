import { GqlZoneItemTransition } from "../../../../store/graphqlTypes";
import { TimelineItem } from "../../../../store/timelines/types";
import { isTimelineItemVoid } from "../../../../utils/contentItemTypeGuards";
import { isCrossTransition } from "./TimelineSlotTransition/transitionUtils";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { TimelinePlaybackState } from "../../../../store/playback/types";

export function useTimelineContentVisible(
  isPreload: boolean,
  isCrossTransition: boolean,
  isActiveItemTransitioningOut: boolean
): boolean {
  return (
    (isPreload && isActiveItemTransitioningOut && isCrossTransition) ||
    !isPreload
  );
}

export function useShouldRenderForPreloading(
  targetTimelineId: string | undefined
): boolean {
  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) =>
    targetTimelineId ? state.playback.timelines[targetTimelineId] : undefined
  );
  return playbackState?.hasPreloadingStarted ?? false;
}

export function useIsPreloadItem(
  targetTimelineId: string | undefined,
  targetItemIndexInTimeline: number | undefined
): boolean {
  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) =>
    typeof targetTimelineId === "string"
      ? state.playback.timelines[targetTimelineId]
      : undefined
  );
  const result =
    !!playbackState && targetItemIndexInTimeline === playbackState.preloadIndex;

  return result;
}

export function e2eTestSlotIdentifier(index: number): string {
  return `slot-${index + 1}`;
}

export function itemPreloadDuration(item: TimelineItem): number | undefined {
  if (!isTimelineItemVoid(item)) {
    return item.preloadDurationMs;
  }

  return undefined;
}

export function getContentTransitionInStartTime(
  contentStartTimestamp: number | undefined,
  contentTransition: GqlZoneItemTransition | undefined
): number {
  if (contentStartTimestamp === undefined) {
    return Infinity;
  }

  if (!contentTransition) {
    return contentStartTimestamp;
  }

  const isCross = isCrossTransition(
    contentTransition?.type,
    contentTransition?.direction
  );

  if (isCross) {
    return contentStartTimestamp - contentTransition.duration;
  } else {
    return contentStartTimestamp;
  }
}
