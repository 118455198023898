var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useMemo, useState } from "react";
import { validateSecureMediaEndpointResponse } from "../util/validateSecureMediaEndpointResponse";
var MAX_RETRY_TIME = 30 * 60 * 60 * 1000;
export var useFetchSecureMediaPolicy = function (input, options) {
    var _a, _b;
    var headers = useMemo(function () {
        var result = new Headers();
        result.append("Authorization", "Bearer " + input.studioAuthToken);
        result.append("Accept", "*/*");
        return result;
    }, [input.studioAuthToken, options.fetchApi]);
    var _c = useSecureMediaFetch({
        endpoint: input.mediaServiceEndpoint,
        fetchApi: options.fetchApi ? options.fetchApi : fetch,
        headers: headers,
        method: "POST",
        validationFunction: validateSecureMediaEndpointResponse,
    }), loading = _c.loading, data = _c.data, error = _c.error, triggerFetch = _c.fetch;
    var lastCallError = error || (data === null || data === void 0 ? void 0 : data.error);
    useRetry({
        isLoading: loading,
        maxRetryTime: input.maxRetryTimeMs,
        retryCallback: triggerFetch,
        hasLastCallErrored: !!lastCallError,
    });
    useTriggerFetchOnExpire(triggerFetch, {
        expireAt: (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.expiresAt,
        retryOffsetBeforeExpireMs: input.retryOffsetBeforeExpireMs,
        deviceTimeOffsetMs: input.deviceTimeOffsetMs,
    });
    useEffect(function () {
        // trigger initial fetch on first render
        triggerFetch();
    }, []);
    return {
        error: error || (data === null || data === void 0 ? void 0 : data.error),
        policy: (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.policy,
    };
};
var useSecureMediaFetch = function (options) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(undefined), data = _b[0], setData = _b[1];
    var _c = useState(undefined), error = _c[0], setError = _c[1];
    var fetchPolicy = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchResponse, responseData, isDataValid, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, options.fetchApi.bind(this)(options.endpoint, {
                            headers: options.headers,
                            method: options.method,
                            redirect: "follow",
                        })];
                case 2:
                    fetchResponse = _a.sent();
                    return [4 /*yield*/, fetchResponse.json()];
                case 3:
                    responseData = (_a.sent());
                    isDataValid = options.validationFunction(responseData);
                    setLoading(false);
                    if (isDataValid) {
                        setData(responseData);
                        setError(undefined);
                    }
                    else {
                        setError({
                            message: "Response shape is invalid",
                        });
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _a.sent();
                    setLoading(false);
                    setError({
                        message: e_1.message,
                    });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        options.endpoint,
        options.fetchApi,
        options.validationFunction,
        options.headers,
        options.method,
    ]);
    return {
        data: data,
        error: error,
        fetch: fetchPolicy,
        loading: loading,
    };
};
var useTriggerFetchOnExpire = function (triggerFn, parameter) {
    var retryOffsetBeforeExpireMs = parameter.retryOffsetBeforeExpireMs, expireAt = parameter.expireAt, deviceTimeOffsetMs = parameter.deviceTimeOffsetMs;
    var offsetBeforeExpire = retryOffsetBeforeExpireMs > 0 ? retryOffsetBeforeExpireMs : 0;
    useEffect(function () {
        if (expireAt) {
            var now = new Date().getTime() + (deviceTimeOffsetMs || 0);
            var timeUntilRefetch = expireAt - now - offsetBeforeExpire;
            if (timeUntilRefetch > 0) {
                var timeout_1 = setTimeout(function () {
                    triggerFn();
                }, timeUntilRefetch);
                return function () {
                    clearTimeout(timeout_1);
                };
            }
            else {
                triggerFn();
            }
        }
    }, [expireAt, triggerFn]);
};
var useRetry = function (options) {
    var maxRetryTime = options.maxRetryTime, retryCallback = options.retryCallback, hasLastCallErrored = options.hasLastCallErrored, isLoading = options.isLoading;
    var _a = useState(0), retryCount = _a[0], setRetryCount = _a[1];
    var maxRetryTimeValue = maxRetryTime !== null && maxRetryTime !== void 0 ? maxRetryTime : MAX_RETRY_TIME;
    useEffect(function () {
        if (hasLastCallErrored && !isLoading) {
            var timeoutMsExponential = Math.pow(2, retryCount) * 1000 * 3;
            var timeoutMs = timeoutMsExponential > maxRetryTimeValue
                ? maxRetryTimeValue
                : timeoutMsExponential;
            var retryTimeout_1 = window.setTimeout(function () {
                setRetryCount(retryCount + 1);
                retryCallback();
            }, timeoutMs);
            return function () {
                window.clearTimeout(retryTimeout_1);
            };
        }
        else {
            setRetryCount(0);
        }
    }, [hasLastCallErrored, isLoading, retryCount, retryCallback]);
};
