import { FileProcessingClientInterface } from "../types/filesClient";
import { IFileUrlOptions } from "@screencloud/signage-files-client/build/classes/FileprocessingClient";
import {
  FileprocessingClient,
  SignedUrlClient,
} from "@screencloud/signage-files-client";
import { produceSecureUrl } from "@screencloud/studio-media-client";

export interface Config {
  imgixDomain: string;
  mediaOrigin: string;
  secureMediaServiceUrl: string | undefined;
}

/**
 * This class is for refactoring purpose. Signage-files-client will be deprecated and replaced by studio-media-client
 */
export class FileProcessingClientWrapped
  implements FileProcessingClientInterface {
  private wrappedClient: FileprocessingClient;
  private secureMediaServiceUrl: string | undefined;

  constructor(config: Config) {
    this.wrappedClient = new FileprocessingClient({
      imgixDomain: config.imgixDomain,
      mediaOrigin: config.mediaOrigin,
      signedUrlClient: new SignedUrlClient({}),
    });
    this.secureMediaServiceUrl = config.secureMediaServiceUrl;
  }

  public getImgixUrl(
    fileKey: string,
    options: IFileUrlOptions,
    secureMediaPolicy?: string
  ) {
    const baseUrl = this.wrappedClient.getImgixUrl(fileKey, options);

    if (secureMediaPolicy && options.size) {
      let urlWithPolicy = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });

      urlWithPolicy = getResizedMedia(
        urlWithPolicy,
        options.size?.h,
        options.size?.w
      );
      return replaceWithSecureURL(
        this.wrappedClient,
        urlWithPolicy,
        this.secureMediaServiceUrl
      );
    } else {
      return baseUrl;
    }
  }

  public getMediaUrlForKey(urlKey: string, secureMediaPolicy?: string) {
    const baseUrl = this.wrappedClient.getMediaUrlForKey(urlKey);

    if (secureMediaPolicy) {
      const urlWithPolicy = produceSecureUrl({
        baseUrl,
        policy: secureMediaPolicy,
      });

      return replaceWithSecureURL(
        this.wrappedClient,
        urlWithPolicy,
        this.secureMediaServiceUrl
      );
    } else {
      return baseUrl;
    }
  }
}

export const replaceWithSecureURL = (
  client: FileprocessingClient,
  targetUrl: string,
  secureMediaServiceUrl: string | undefined
) => {
  const { imgixDomain, mediaOrigin } = client.config;
  // list of valid screen cloud media origins
  const validOrigins = [`${imgixDomain}`, `${mediaOrigin}`];

  const reg = new RegExp("^https?://[^#?/]+");

  if (
    validOrigins.filter((domain) => targetUrl.includes(domain)).length > 0 &&
    secureMediaServiceUrl
  ) {
    return targetUrl.replace(reg, secureMediaServiceUrl);
  }

  return targetUrl;
};

/**
 * returns resized media url
 */

export const getResizedMedia = (
  url: string,
  height: number,
  width: number,
  fit = "cover"
) => {
  return url?.replace("originals", `${width}x${height}_${fit}`);
};
