/**
 * Is this a local dev build?
 */
export const isLocalDev = (): boolean => {
  return process.env.REACT_APP_SC_ENV === "development";
};

export const isProduction = (): boolean => {
  return process.env.REACT_APP_SC_ENV === "production";
};

export const isStorePersistDisabledFlag = (): boolean => {
  const urlParams = new window.URLSearchParams(window.location.search);
  return urlParams.has("disable_store_persist");
};

export const isUrlAppsDomain = (url: string): boolean => {
  const urlToCheck = new URL(url);
  const domain = urlToCheck.hostname.toString();
  return (
    domain.endsWith(".apps.next.sc") ||
    domain.endsWith(".apps.screencloud.com") ||
    domain.endsWith(".screencloudapps.com")
  );
};
