import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LiveUpdateItemType } from "../../../../store/liveUpdates/types";
import { EntityType } from "@screencloud/signage-firestore-client";
import {
  AppInstanceByIdQuery,
  ChannelByIdQuery,
  FileByIdQuery,
  LayoutByIdQuery,
  LinkByIdQuery,
  PlaylistByIdQuery,
  SpaceByIdQuery,
  useAppInstanceById,
  useChannelById,
  useFileById,
  useLayoutById,
  useLinkById,
  usePlaylistById,
  useScreenById,
  useSpaceById,
} from "../../../../queries";
import { requestChannelSuccess } from "../../../../store/channels/actions";
import { useDispatch, useSelector } from "react-redux";
import { requestScreenSuccess } from "../../../../store/screen/actions";
import {
  removeAppInstance,
  requestAppSuccess,
} from "../../../../store/apps/actions";
import { requestPlaylistSuccess } from "../../../../store/playlists/actions";
import { requestSpaceSuccess } from "../../../../store/spaces/actions";
import { requestLayoutSuccess } from "../../../../store/layouts/actions";
import { requestFileSuccess } from "../../../../store/files/actions";
import { useLiveUpdate } from "../../../../store/liveUpdates/useLiveUpdateHook";
import { PlayerState } from "../../../../store/rootReducer";
import { Channel, ChannelsState } from "../../../../store/channels/types";
import { ContentListsState } from "../../../../store/contentLists/types";
import { ScreenState } from "../../../../store/screen/types";
import { parseContentListId } from "../../../../store/contentLists/utils";
import { getRootContent } from "../../../../utils/rootContent";
import { ConfigState } from "../../../../store/config/types";
import { ScreenByIdQuery } from "../../../../queries/";
import { ThemesState } from "../../../../store/themes/types";
import { OrganizationState } from "../../../../store/organization/types";
import { requestLinkSuccess } from "../../../../store/links/actions";
import { Logger } from "../../../../logger/logger";

const log = new Logger("LiveUpdateConnector");

export interface LiveUpdateConnectorProps {
  entityId: string;
  entityType: LiveUpdateItemType;
  customFetchFunction?: () => Promise<unknown>; // this is for test purpose
}

export const LiveUpdateConnector: FunctionComponent<LiveUpdateConnectorProps> = memo(
  ({ entityId, entityType, customFetchFunction }: LiveUpdateConnectorProps) => {
    switch (entityType) {
      case EntityType.CHANNEL:
        return (
          <LiveUpdateConnectorChannel
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.APP_INSTANCE:
        return (
          <LiveUpdateConnectorAppInstance
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.FILE:
        return (
          <LiveUpdateConnectorFile
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.PLAYLIST:
        return (
          <LiveUpdateConnectorPlaylist
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.SCREEN:
        return (
          <LiveUpdateConnectorScreen
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.LINK:
        return (
          <LiveUpdateConnectorLink
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.SPACE:
        return (
          <LiveUpdateConnectorSpace
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      case EntityType.LAYOUT:
        return (
          <LiveUpdateConnectorLayout
            id={entityId}
            customFetchFunction={customFetchFunction}
          />
        );
      default:
        throw new Error(`Can't recognise entity type: ${entityType}`);
    }
  }
);

export interface SpecificConntectorProps {
  id: string;
  customFetchFunction?: () => Promise<unknown>; // this is for test purpose
}

const LiveUpdateConnectorChannel: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [themeId, setThemeId] = useState<string>("");

    const [customFetch, { data: customData }] = useCustomFetch<
      ChannelByIdQuery
    >(customFetchFunction);

    const dispatch = useDispatch();

    const channel = useSelector<PlayerState, Channel | undefined>(
      (state) => state.channels.byId[id]
    );

    const organization = useSelector<
      PlayerState,
      OrganizationState | undefined
    >((state) => state.organization);

    const themes = useSelector<PlayerState, ThemesState | undefined>(
      (state) => state.themes
    );

    const [fetchChannel, { data }] = useChannelById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (themes?.byId) {
        const currentThemeId = channel?.themeId
          ? themes?.byId[channel.themeId].id
          : organization?.defaultThemeId;
        if (currentThemeId) {
          setThemeId(currentThemeId);
        }
      }
    }, [themeId, channel?.themeId, themes?.byId, organization]);

    useEffect(() => {
      if (data?.channelById) {
        log.info(
          `Updated channel has been fetched. (id: ${data.channelById.id}).`
        );
        dispatch(requestChannelSuccess(data.channelById));
      }
    }, [data?.channelById, dispatch]);

    useEffect(() => {
      if (customData?.channelById) {
        dispatch(requestChannelSuccess(customData.channelById));
      }
    }, [customData?.channelById, dispatch]);

    useLiveUpdate(
      EntityType.CHANNEL,
      id,
      customFetch ? customFetch : fetchChannel
    );

    useLiveUpdate(
      EntityType.THEME,
      themeId,
      customFetch ? customFetch : fetchChannel
    );

    return <></>;
  }
);

const LiveUpdateConnectorScreen: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<ScreenByIdQuery>(
      customFetchFunction
    );

    const dispatch = useDispatch();

    const [fetchScreen, { data }] = useScreenById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data) {
        log.info(
          `Updated screen has been fetched (id: ${data.screenById?.id}).`
        );
        dispatch(requestScreenSuccess(data));
      }
    }, [data, dispatch]);

    useEffect(() => {
      if (customData) {
        dispatch(requestScreenSuccess(customData));
      }
    }, [customData, dispatch]);

    useLiveUpdate(
      EntityType.SCREEN,
      id,
      customFetch ? customFetch : fetchScreen
    );

    return <></>;
  }
);

const LiveUpdateConnectorLink: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<LinkByIdQuery>(
      customFetchFunction
    );

    const dispatch = useDispatch();

    const [fetchLink, { data }] = useLinkById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data?.linkById) {
        log.info(`Updated link has been fetched (id: ${data.linkById.id}).`);
        dispatch(requestLinkSuccess(data.linkById));
      }
    }, [data?.linkById, dispatch]);

    useEffect(() => {
      if (customData?.linkById) {
        dispatch(requestLinkSuccess(customData.linkById));
      }
    }, [customData?.linkById, dispatch]);

    useLiveUpdate(EntityType.LINK, id, customFetch ? customFetch : fetchLink);

    return <></>;
  }
);

const LiveUpdateConnectorAppInstance: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<
      AppInstanceByIdQuery
    >(customFetchFunction);

    const dispatch = useDispatch();

    const [fetchAppInstance, { data }] = useAppInstanceById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      // If an app is deleted from the libary request will return null
      // should only dispatch if appInstanceById exists.
      if (data?.appInstanceById) {
        log.info(`Updated app instance has been fetched (id: ${id}).`);
        dispatch(requestAppSuccess(data.appInstanceById));
      } else if (data && !data.appInstanceById) {
        log.info(`App instance has been removed on live update (id: ${id}).`);
        dispatch(removeAppInstance(id));
      }
    }, [data, dispatch, id]);

    useEffect(() => {
      if (customData?.appInstanceById) {
        dispatch(requestAppSuccess(customData.appInstanceById));
      }
    }, [customData?.appInstanceById, dispatch]);

    useLiveUpdate(
      EntityType.APP_INSTANCE,
      id,
      customFetch ? customFetch : fetchAppInstance
    );

    return <></>;
  }
);

const LiveUpdateConnectorFile: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<FileByIdQuery>(
      customFetchFunction
    );

    const dispatch = useDispatch();

    const [fetchFile, { data }] = useFileById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data?.fileById) {
        log.info(`Updated file has been fetched (id: ${data.fileById.id}).`);
        dispatch(requestFileSuccess(data.fileById));
      }
    }, [data?.fileById, dispatch]);

    useEffect(() => {
      if (customData?.fileById) {
        dispatch(requestFileSuccess(customData.fileById));
      }
    }, [customData?.fileById, dispatch]);

    useLiveUpdate(EntityType.FILE, id, customFetch ? customFetch : fetchFile);

    return <></>;
  }
);

const LiveUpdateConnectorPlaylist: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<
      PlaylistByIdQuery | ChannelByIdQuery
    >(customFetchFunction);

    const dispatch = useDispatch();

    const [fetchPlaylist, { data }] = usePlaylistById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    const channelIdToReload = useActiveChannelWithNestedPlaylist(id);

    const [fetchChannel, { data: channelData }] = useChannelById({
      useCache: false,
      skipCache: true,
      variables: {
        id: channelIdToReload,
      },
    });

    useEffect(() => {
      if (data?.playlistById) {
        log.info(`Updated playlist fetched (id: ${data.playlistById.id}).`);
        dispatch(requestPlaylistSuccess(data.playlistById));
      }
      if (channelData?.channelById) {
        log.info(
          `Updated channel fetched (id: ${channelData.channelById.id}).`
        );
        dispatch(requestChannelSuccess(channelData.channelById));
      }
    }, [data?.playlistById, channelData?.channelById, dispatch]);

    useEffect(() => {
      if (customData) {
        if (
          channelIdToReload &&
          "channelById" in customData &&
          customData.channelById
        ) {
          dispatch(requestChannelSuccess(customData.channelById));
        } else if ("playlistById" in customData && customData.playlistById) {
          dispatch(requestPlaylistSuccess(customData.playlistById));
        }
      }
    }, [customData, dispatch, channelIdToReload]);

    const fetchCallback = useCallback(() => {
      // The reason for this conditional fetch logic is the problem with nested playlist live updates that we
      //  had at some point in the past: https://github.com/screencloud/studio-player/issues/845. It may be worth
      //  revisiting this solution
      if (channelIdToReload) {
        return fetchChannel();
      } else {
        return fetchPlaylist();
      }
    }, [channelIdToReload, fetchPlaylist, fetchChannel]);

    useLiveUpdate(
      EntityType.PLAYLIST,
      id,
      customFetch ? customFetch : fetchCallback
    );

    return <></>;
  }
);

const LiveUpdateConnectorSpace: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<SpaceByIdQuery>(
      customFetchFunction
    );

    const dispatch = useDispatch();

    const [fetchSpace, { data }] = useSpaceById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data?.spaceById) {
        log.info(`Updated space fetched (id: ${data.spaceById.id}).`);
        dispatch(requestSpaceSuccess(data.spaceById));
      }
    }, [data?.spaceById, dispatch]);

    useEffect(() => {
      if (customData?.spaceById) {
        dispatch(requestSpaceSuccess(customData.spaceById));
      }
    }, [customData?.spaceById, dispatch]);

    useLiveUpdate(EntityType.SPACE, id, customFetch ? customFetch : fetchSpace);

    return <></>;
  }
);

const LiveUpdateConnectorLayout: FunctionComponent<SpecificConntectorProps> = memo(
  ({ id, customFetchFunction }: SpecificConntectorProps) => {
    const [customFetch, { data: customData }] = useCustomFetch<LayoutByIdQuery>(
      customFetchFunction
    );

    const dispatch = useDispatch();

    const [fetchLayout, { data }] = useLayoutById({
      useCache: false,
      skipCache: true,
      variables: {
        id,
      },
    });

    useEffect(() => {
      if (data?.layoutById) {
        log.info(`Updated layout fetched (id: ${data.layoutById.id}).`);
        dispatch(requestLayoutSuccess(data.layoutById));
      }
    }, [data?.layoutById, dispatch]);

    useEffect(() => {
      if (customData?.layoutById) {
        dispatch(requestLayoutSuccess(customData.layoutById));
      }
    }, [customData?.layoutById, dispatch]);

    useLiveUpdate(
      EntityType.LAYOUT,
      id,
      customFetch ? customFetch : fetchLayout
    );

    return <></>;
  }
);

function useCustomFetch<T>(
  customFetchFunction: (() => Promise<unknown>) | undefined
): [
  (() => Promise<unknown>) | undefined,
  {
    data: T | undefined;
    error?: { message: string };
  }
] {
  const [customDataResponse, setCustomDataResponse] = useState<T | undefined>(
    undefined
  );
  const [error, setError] = useState<{ message: string } | undefined>(
    undefined
  );

  const customFetch = useMemo(() => {
    if (customFetchFunction) {
      return async () => {
        try {
          const dataResponse = (await customFetchFunction()) as T;
          setCustomDataResponse(dataResponse);
          setError(undefined);
        } catch (err) {
          if (err) {
            setError({ message: JSON.stringify(err) });
          }
          setCustomDataResponse(undefined);
        }
      };
    } else {
      return undefined;
    }
  }, [customFetchFunction]);

  return [customFetch, { data: customDataResponse, error }];
}

// to get the channelId in case it return undefine it's mean this playlist is not the nested playlist in any channel
// if it return thr channelId it's mean this playlist is the nested playlist
function useActiveChannelWithNestedPlaylist(
  playlistId: string
): string | undefined {
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);

  const channels = useSelector<PlayerState, ChannelsState>(
    (state) => state.channels
  );

  const contentLists = useSelector<PlayerState, ContentListsState>(
    (state) => state.contentLists
  );

  const config = useSelector<PlayerState, ConfigState>((state) => state.config);
  const channelIdToReload = useRef<string | undefined>(undefined);

  useEffect(() => {
    Object.entries(contentLists.byId)
      // filter only the contentList that has the `nestedPlaylistIds` value and also contain the target `playlistId`.
      .filter(([_contentId, value]) =>
        value.nestedPlaylistIds?.includes(playlistId)
      )
      .forEach(([contentId, _value]) => {
        // get the layoutId from contentList
        const parsedContentListId = parseContentListId(contentId);
        if (parsedContentListId.type !== "channel-zone") {
          return;
        }

        const { layoutId } = parsedContentListId;

        // find all the channel that have layoutId equal to the layoutId from contentList
        const channelIds = Object.entries(channels.byId)
          .filter(
            ([_channelId, channelValue]) => channelValue.layoutId === layoutId
          )
          .map(([channelId, _channelValue]) => channelId);

        // get the rootContent
        const rootContent = getRootContent(config.contentConfig);
        if (rootContent.type === "screen") {
          const activeItem = screen.activeContentItem;
          // if the activeItem.type is channel and activeItem.id is one of the item in channelIds list >> return activeItem.id
          if (
            activeItem?.type === "channel" &&
            channelIds.includes(activeItem.id)
          ) {
            channelIdToReload.current = activeItem.id;
          }
        }

        if (rootContent.type === "channel") {
          // if rootContent.id is one of the item in channelIds list >> return rootContent.id
          if (channelIds.includes(rootContent.id)) {
            channelIdToReload.current = rootContent.id;
          }
        }
      });
  }, [
    playlistId,
    channels.byId,
    contentLists.byId,
    config.contentConfig,
    screen.activeContentItem,
  ]);

  return channelIdToReload.current;
}
