import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import {
  Timeline,
  TimelineItem,
  TimelineType,
} from "../../../../store/timelines/types";
import { PlayerState } from "../../../../store/rootReducer";
import TimelineOperatorLocal from "./TimelineOperatorLocal";
import { ContentFailureCallback, TimelineOperatorPropsBase } from "./types";
import { TimelinePlaybackState } from "../../../../store/playback/types";
import {
  nextItemAction,
  setActiveItemTransitioningOut,
  setTimelinePreviewItem,
} from "../../../../store/playback/actions";
import {
  TimeOptions,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import { useTimeOptions } from "../../../../utils/useTimeOptions";
import { usePreviousValue } from "../../../../utils/usePreviousValue";
import { TimelinePlaybackReportContainer } from "./TimelinePlaybackReportContainer";
import { ContentListUpdateReportContainer } from "./ContentListUpdateReportContainer";
import { parseLocalTimelineId } from "../../../../store/contentLists/utils";
import { useContentFailureCallback } from "./hooks/useContentFailureCallback";
import { ContentList } from "../../../../store/contentLists/types";
import { TimelineSlot } from "./TimelineSlot/TimelineSlot";
import { Logger } from "../../../../logger/logger";
import { useIsItemTransitioningOut } from "./hooks/useIsItemTransitioningOut";
import { SlotContent } from "./TimelineSlot/SlotContent";
import { useSetIsActiveItemTransitioningOut } from "./hooks/useSetIsActiveItemTransitioningOut";
import { useSetHasPreloadingStarted } from "./hooks/useSetHasPreloadingStarted";
import { isTimelineItemVoid } from "../../../../utils/contentItemTypeGuards";
const log = new Logger("TimelineViewer");

interface TimelineViewerContainerProps {
  id: string;
}

export const TimelineViewerContainer: FunctionComponent<TimelineViewerContainerProps> = ({
  id,
}: TimelineViewerContainerProps) => {
  const previewItem: TimelineItem | null = useSelector<
    PlayerState,
    TimelineItem | null
  >((state) => state.playback.controls.previewItem);

  const dispatch = useDispatch();
  const timeline = useSelector<PlayerState, Timeline | undefined>(
    (state) => state.timelines.byId[id]
  );
  const targetContentList = useSelector<PlayerState, ContentList | undefined>(
    (state) => {
      const contentListId = parseLocalTimelineId(id).sourceContentListId;
      return state.contentLists.byId[contentListId];
    }
  );
  const playbackState = useSelector<
    PlayerState,
    TimelinePlaybackState | undefined
  >((state) => state.playback.timelines[id]);
  const timelineType = useSelector<PlayerState, TimelineType>(
    (state) => state.timelines.type
  );

  const timeOptions = useTimeOptions();

  // effect clears the preview item after it run for show duration
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setTimelinePreviewItem(null));
    }, previewItem?.showDurationMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [previewItem, dispatch]);

  const onActiveItemEnd = useCallback(() => {
    if (!timeline) {
      return;
    }
    // Action below is dispatched here to avoid any flickering of transitions, which occasionally may happen if this
    //  action is dispatched on a separate timeout
    dispatch(setActiveItemTransitioningOut(false, timeline.id));

    // we add 10 ms to utcNow() value to make sure there is no race condition when determining the active item,
    //  because time comparisons are done with 1ms precision
    dispatch(
      nextItemAction(timeline.id, playbackNowTimestamp(timeOptions) + 10)
    );
  }, [timeline, dispatch, timeOptions]);

  const onContentFailure = useContentFailureCallback(
    timeline,
    targetContentList,
    timeOptions,
    dispatch
  );

  const activeItem =
    typeof playbackState?.activeIndex === "number"
      ? timeline?.items[playbackState?.activeIndex]
      : undefined;
  const nextItem =
    typeof playbackState?.activeIndex === "number"
      ? timeline?.items[playbackState?.activeIndex + 1]
      : undefined;

  useSetIsActiveItemTransitioningOut(
    id,
    activeItem,
    nextItem?.startTimestampMs,
    timeOptions,
    dispatch
  );

  useSetHasPreloadingStarted(
    id,
    nextItem?.startTimestampMs,
    nextItem && !isTimelineItemVoid(nextItem)
      ? nextItem?.preloadDurationMs
      : undefined,
    timeOptions,
    dispatch
  );

  if (!timeline) {
    return null;
  }

  return (
    <>
      <TimelinePlaybackReportContainer timelineId={id} />
      {/*
        ContentListUpdateReportContainer is placed here because we're interested in reporting only content lists
        that are involved into actual playback at the moment.
      */}
      <ContentListUpdateReportContainer
        contentListId={parseLocalTimelineId(id).sourceContentListId}
        timeline={timeline}
      />
      <TimelineOperator timelineId={id} type={timelineType} />
      {!playbackState ? null : (
        <TimelineViewer
          timeline={timeline}
          playbackState={playbackState}
          timeOptions={timeOptions}
          onActiveItemEnd={onActiveItemEnd}
          onContentFailure={onContentFailure}
          timelineType={timelineType}
          previewPlayItem={previewItem}
        />
      )}
    </>
  );
};

const TimelineOperator: FunctionComponent<
  TimelineOperatorPropsBase & {
    type: TimelineType;
  }
> = (props: TimelineOperatorPropsBase & { type: TimelineType }) => {
  switch (props.type) {
    case "local":
      return <TimelineOperatorLocal {...props} />;
    case "test":
      return null;
    default:
      return <TimelineOperatorLocal {...props} />;
  }
};

interface TimelineViewerProps {
  timeline: Pick<Timeline, "items" | "id">;
  playbackState: Pick<
    TimelinePlaybackState,
    "activeIndex" | "preloadIndex" | "isActiveItemTransitioningOut"
  >;
  timeOptions: TimeOptions;
  onActiveItemEnd: () => void;
  onContentFailure: ContentFailureCallback;
  timelineType: TimelineType;
  forcePreloadMode?: boolean; // all items will be rendered in preload mode only
  previewPlayItem?: TimelineItem | null;
}

interface RenderSlot {
  item: TimelineItem | undefined;
  indexInTimeline: number | undefined;
  isPreload: boolean;
}

/**
 * Checks if an item inside the slot represents the targetTimelineItem
 */
function doesSlotRepresentTimelineItem(
  slotItem: TimelineItem | undefined,
  targetTimelineItem: TimelineItem | undefined
): boolean {
  return (
    slotItem !== undefined &&
    targetTimelineItem !== undefined &&
    slotItem.type === targetTimelineItem.type &&
    ((slotItem.type !== "void" &&
      targetTimelineItem.type !== "void" &&
      // checking list id is not enough, startTimestamp must be compared too, but I leave it like this for now until
      //  we solve https://github.com/screencloud/studio-player/issues/316
      //  https://github.com/screencloud/studio-player/issues/317,
      //  https://github.com/screencloud/studio-player/issues/318, cause otherwise a change of a slot causes
      //  apps restart, which in case of youtube causes video restart, that is required to be avoided
      slotItem.listId === targetTimelineItem.listId) ||
      (slotItem.type === "void" &&
        targetTimelineItem.type === "void" &&
        slotItem.startTimestampMs === targetTimelineItem.startTimestampMs))
  );
}

export const TimelineViewer: FunctionComponent<TimelineViewerProps> = memo(
  ({
    timeline,
    playbackState,
    timeOptions,
    onActiveItemEnd,
    timelineType,
    onContentFailure,
    forcePreloadMode,
    previewPlayItem: forcePlayItem,
  }: TimelineViewerProps) => {
    const [renderSlots, setRenderSlots] = useState<RenderSlot[]>([]);
    const [itemSwitchTicker, setItemSwitchTicker] = useState<number>(0);

    const getActiveItem = (): TimelineItem | undefined => {
      /**
       * 1. set preview item as active playing
       * 2. check if this item is part of current playing timeline
       */
      if (forcePlayItem && timeline.items.includes(forcePlayItem)) {
        return forcePlayItem;
      }

      // return active playback item
      const item =
        playbackState.activeIndex !== undefined
          ? timeline.items[playbackState.activeIndex]
          : undefined;

      return item;
    };

    const activeItem: TimelineItem | undefined = getActiveItem();
    const activeItemIndex: number | undefined = forcePlayItem
      ? timeline.items.indexOf(forcePlayItem)
      : playbackState.activeIndex;
    const nextItem: TimelineItem | undefined =
      playbackState.preloadIndex !== undefined
        ? timeline.items[playbackState.preloadIndex]
        : undefined;
    const nextItemIndex: number | undefined = playbackState.preloadIndex;
    const nextItemPrev = usePreviousValue(nextItem);

    const activeTransition: number = activeItem?.transition?.duration || 0;

    const isActiveItemTransitioningOut = useIsItemTransitioningOut(
      playbackState
    );

    useEffect(() => {
      if (nextItem) {
        const now = playbackNowTimestamp(timeOptions);

        let activeItemScreenTimeMs =
          nextItem.startTimestampMs - now >= 0
            ? nextItem.startTimestampMs - now
            : 0;

        // if nextItem stays the same at this point and active screen time equals zero (which means it's time to switch
        //  to different item) - there must be a logic flaw on playback state update side. We artificially extend
        //  duration timeout here to avoid infinite timeout execution.
        // const activeItemSwitchTimeoutValue = activeItemScreenTimeMs; // default play duration to be overwrite

        if (nextItem === nextItemPrev && activeItemScreenTimeMs === 0) {
          log.info(
            "TimelineViewer: activeItemScreenTimeMs is 0 and nextItem is duplicate, if found this message please come back to studio-player team to check the code."
          );
          // todo: verify this error and come back to fix the edge case Vic's mention above
          // return;
          activeItemScreenTimeMs = 200;
        }

        const durationTimeout = window.setTimeout(() => {
          onActiveItemEnd();

          // switch ticker is used to make sure the component never gets stuck in a state without the duration timeout
          setItemSwitchTicker(itemSwitchTicker === 0 ? 1 : 0);
        }, activeItemScreenTimeMs);

        return (): void => {
          window.clearTimeout(durationTimeout);
        };
      }
    }, [
      activeItem,
      nextItem,
      activeTransition,
      timeOptions,
      onActiveItemEnd,
      nextItemPrev,
      // switch ticker forces the duration timeout to be reset on each timeout callback execution to avoid
      itemSwitchTicker,
    ]);

    useEffect(() => {
      // this effect always sets 2 slots. Even if there is no content to show or preload.

      const updatedSlots: RenderSlot[] = [];
      const existingActiveItemSlotIndex = renderSlots.findIndex((slot) =>
        doesSlotRepresentTimelineItem(slot.item, activeItem)
      );
      const existingPreloadItemSlotIndex = renderSlots.findIndex((slot) =>
        doesSlotRepresentTimelineItem(slot.item, nextItem)
      );
      const freeSlotIndexes: number[] = [0, 1].filter(
        (indexNumber) =>
          ![existingActiveItemSlotIndex, existingPreloadItemSlotIndex].includes(
            indexNumber
          )
      );

      const activeItemSlotIndex =
        existingActiveItemSlotIndex > -1
          ? existingActiveItemSlotIndex
          : freeSlotIndexes.pop();
      const preloadItemSlotIndex =
        existingPreloadItemSlotIndex > -1 &&
        existingPreloadItemSlotIndex !== existingActiveItemSlotIndex
          ? existingPreloadItemSlotIndex
          : freeSlotIndexes.pop();

      if (activeItemSlotIndex !== undefined) {
        updatedSlots[activeItemSlotIndex] = {
          item: activeItem,
          indexInTimeline: activeItemIndex,
          isPreload: false,
        };
      } else {
        throw new Error("Slot index can not be undefined");
      }
      if (preloadItemSlotIndex !== undefined) {
        updatedSlots[preloadItemSlotIndex] = {
          item: nextItem,
          indexInTimeline: nextItemIndex,
          isPreload: true,
        };
      } else {
        throw new Error("Slot index can not be undefined");
      }

      if (!isEqual(updatedSlots, renderSlots)) {
        setRenderSlots(updatedSlots);
      }
    }, [activeItem, nextItem, activeItemIndex, nextItemIndex, renderSlots]);

    return (
      <>
        {
          /* Main purpose of "render slot": keep a DOM node representing a content item at exactly same place in the
           * DOM tree when switching from preload mode to active mode. This is especially important for iframes, rendered
           * for preloading - chrome reloads iframe's url if you move the iframe node in a DOM tree.
           * */
          renderSlots.map((slot, idx) => {
            return (
              <TimelineSlot
                index={idx}
                key={idx}
                transition={slot.item?.transition}
                isActiveItemTransitioningOut={isActiveItemTransitioningOut}
                targetTimelineId={timeline.id}
                indexInTimeline={slot.indexInTimeline}
              >
                {slot.item && !slot.item.isFullscreen && (
                  <SlotContent
                    item={slot.item}
                    isPreload={forcePreloadMode || slot.isPreload}
                    onContentFailure={onContentFailure}
                    timelineItemIndex={
                      (slot.isPreload
                        ? playbackState.preloadIndex
                        : playbackState.activeIndex) as number
                    }
                  />
                )}
              </TimelineSlot>
            );
          })
        }
      </>
    );
  }
);
TimelineViewer.displayName = "TimelineViewer";
