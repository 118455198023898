"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirestoreClient = void 0;
var app_1 = require("firebase/app");
require("firebase/database");
require("firebase/firestore");
var FirestoreClient = /** @class */ (function () {
    function FirestoreClient(options) {
        var _this = this;
        this.networkStatusChangeHandler = function (snapshot) {
            var isOnline = snapshot.val() === true;
            if (_this.onNetworkStatusChange) {
                _this.onNetworkStatusChange(isOnline);
            }
        };
        this.firebaseAppInstance = app_1.default.initializeApp(options.firebase, options.projectName);
        this.firestore = app_1.default.firestore(this.firebaseAppInstance);
        this.organizationId = options.organizationId;
        this.onNetworkStatusChange = options.onNetworkStatusChange;
        this.networkStatusRef = app_1.default.database(this.firebaseAppInstance).ref(".info/connected");
        this.networkStatusRef.on("value", this.networkStatusChangeHandler);
    }
    Object.defineProperty(FirestoreClient.prototype, "projectName", {
        get: function () {
            return this.firebaseAppInstance.name;
        },
        enumerable: false,
        configurable: true
    });
    FirestoreClient.prototype.subscribeToOrganizationChange = function (subscriptionCallback, skipInitialCallback) {
        if (skipInitialCallback === void 0) { skipInitialCallback = true; }
        var callback = this.getOnSnapshotCallbackEntityType(subscriptionCallback, skipInitialCallback);
        var docOrg = this.firestore
            .collection("organisations")
            .doc(this.organizationId);
        return docOrg.onSnapshot(callback);
    };
    FirestoreClient.prototype.subscribeToEntityChange = function (entityType, entityId, subscriptionCallback, skipInitialCallback) {
        if (skipInitialCallback === void 0) { skipInitialCallback = true; }
        var callback = this.getOnSnapshotCallbackEntityType(subscriptionCallback, skipInitialCallback);
        var entityDoc = this.firestore
            .collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .doc(entityId);
        return entityDoc.onSnapshot(callback);
    };
    FirestoreClient.prototype.subscribeToCollectionChange = function (entityType, subscriptionCallback, skipInitialCallback) {
        if (skipInitialCallback === void 0) { skipInitialCallback = true; }
        var callback = this.getOnSnapshotCallbackCollectionType(subscriptionCallback, skipInitialCallback);
        var collectionList = this.firestore
            .collection("organisations")
            .doc(this.organizationId)
            .collection(entityType);
        return collectionList.onSnapshot(callback);
    };
    FirestoreClient.prototype.unsubscribeFromEntityChange = function (entityType, entityId) {
        var unsubscribe = this.firestore
            .collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .doc(entityId)
            .onSnapshot(function () { return null; });
        unsubscribe();
    };
    FirestoreClient.prototype.unsubscribeFromCollectionChange = function (entityType) {
        var unsubscribe = this.firestore
            .collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .onSnapshot(function () { return null; });
        unsubscribe();
    };
    FirestoreClient.prototype.isPurged = function () {
        var _this = this;
        return app_1.default.apps.findIndex(function (app) { return app.name === _this.firebaseAppInstance.name; }) === -1;
    };
    FirestoreClient.prototype.purge = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.networkStatusRef.off("value", this.networkStatusChangeHandler);
                        return [4 /*yield*/, this.firebaseAppInstance.delete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FirestoreClient.prototype.getOnSnapshotCallbackCollectionType = function (callbackFunction, skipInitialCallback) {
        /**
         * This skipInitialCallback is needed because .onSnapshot() is not just subscribing to firestore document
         * update, it's calling the callback first time right after you invoked .onSnapshot() with the current state of
         * the document. We want to be able to skip that initial call, just subscribe to future updates
         */
        return skipInitialCallback
            ? (function () {
                var isInitialCall = true;
                return function (snapshot) {
                    if (isInitialCall) {
                        isInitialCall = false;
                        return;
                    }
                    return callbackFunction(snapshot);
                };
            })()
            : callbackFunction;
    };
    FirestoreClient.prototype.getOnSnapshotCallbackEntityType = function (callbackFunction, skipInitialCallback) {
        /**
         * This skipInitialCallback is needed because .onSnapshot() is not just subscribing to firestore document
         * update, it's calling the callback first time right after you invoked .onSnapshot() with the current state of
         * the document. We want to be able to skip that initial call, just subscribe to future updates
         */
        return skipInitialCallback
            ? (function () {
                var isInitialCall = true;
                return function (snapshot) {
                    if (isInitialCall) {
                        isInitialCall = false;
                        return;
                    }
                    return callbackFunction(snapshot);
                };
            })()
            : callbackFunction;
    };
    return FirestoreClient;
}());
exports.FirestoreClient = FirestoreClient;
