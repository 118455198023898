/**
 * IMPORTANT:
 * Cloud links are deprecated in studio, this code will be removed once confirmed that there is no need for
 * backward compatibility
 */
import { Link } from "../../../../store/links/types";
import React, { FunctionComponent, memo, useEffect, useState } from "react";
import { Loading } from "../../../core/components/Loading/Loading";
import { ImageViewer } from "../ImageViewer/ImageViewer";
import { Logger } from "../../../../logger/logger";
import memoize from "lodash/memoize";
import { LinkCloudServiceClient } from "@screencloud/signage-link-client";
import { ContentFailureGenericCb } from "../TimelineViewer/types";

const log = new Logger("linkViewer");

enum CloudLinkStatus {
  STARTED = "STARTED",
  FINISHED = "FINISHED",
  ERROR = "ERROR",
  NOT_STARTED = "NOT_STARTED",
}

interface LinkViewerCloudContainerProps {
  link: Link;
  linkCloudServiceUrl: string;
  height: number;
  width: number;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
}

/**
 * The link cloud service client provides methods to access the site links either through cloud rendered screenshot or
 * accessed through iframe
 * @param linkCloudServiceUrl - origin of where the files are stored in studio
 */
const createLinkCloudServiceClient = memoize((linkCloudServiceUrl: string) => {
  return new LinkCloudServiceClient({
    cloudServiceUrl: linkCloudServiceUrl,
  });
});

export const getContentSrc = async (
  linkCloudServiceUrl: string,
  height: number,
  width: number,
  linkUrl: string,
  cloudConfigCredential: string | undefined
): Promise<string | null> => {
  if (!linkUrl) {
    return null;
  }
  const linkCloudServiceClient = createLinkCloudServiceClient(
    linkCloudServiceUrl
  );
  try {
    return await linkCloudServiceClient.getScreenshotUrl({
      height,
      linkCredential: cloudConfigCredential || null,
      linkTargetUrl: linkUrl,
      width,
    });
  } catch (error) {
    log.error({
      message: "error fetching content from link",
      context: {
        error: JSON.stringify(error),
      },
    });
    return null;
  }
};

export const LinkViewerCloudContainer: FunctionComponent<LinkViewerCloudContainerProps> = (
  props
) => {
  const {
    link,
    width,
    height,
    linkCloudServiceUrl,
    isPreload,
    onContentFailure,
  } = props;
  const [contentSrc, setContentSrc] = useState<string | null>(null);
  const [cloudLinkStatus, setCloudLinkStatus] = useState<CloudLinkStatus>(
    CloudLinkStatus.NOT_STARTED
  );

  useEffect(() => {
    if (cloudLinkStatus === CloudLinkStatus.NOT_STARTED) {
      setCloudLinkStatus(CloudLinkStatus.STARTED);
      const getAndSetContentSrc = async (): Promise<void> => {
        const src = await getContentSrc(
          linkCloudServiceUrl,
          height,
          width,
          link.url,
          link.cloudConfigCredential
        );
        setCloudLinkStatus(CloudLinkStatus.FINISHED);
        setContentSrc(src);
      };

      try {
        getAndSetContentSrc();
      } catch (error) {
        console.error(error);
        setCloudLinkStatus(CloudLinkStatus.ERROR);
      }
    }
  }, [
    linkCloudServiceUrl,
    link.url,
    link.cloudConfigCredential,
    height,
    width,
    cloudLinkStatus,
  ]);

  if (cloudLinkStatus === CloudLinkStatus.STARTED || !contentSrc) {
    return <Loading />;
  }

  if (cloudLinkStatus === CloudLinkStatus.ERROR) {
    return <p>Error: Could not get cloud render link.</p>;
  }

  return (
    <LinkViewerCloud
      url={contentSrc}
      name={link.name}
      width={width}
      height={height}
      isPreload={isPreload}
      onContentFailure={onContentFailure}
    />
  );
};
LinkViewerCloudContainer.displayName = "LinkViewerCloudContainer";

export interface LinkViewerCloudProps {
  url: string;
  name: string;
  width: number;
  height: number;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
}

export const LinkViewerCloud: FunctionComponent<LinkViewerCloudProps> = memo(
  (props) => {
    const { width, height, url, name, isPreload, onContentFailure } = props;

    useEffect(() => {
      log.info({
        message: `Show Link ${url}`,
        context: {
          name,
          contentType: "Link",
          linkType: "CLOUD",
          isPreload,
        },
      });
    }, [url, name, isPreload]);

    return (
      <ImageViewer
        src={url}
        name={name}
        containerSize={{ width, height }}
        isPreload={isPreload}
        onContentFailure={onContentFailure}
      />
    );
  }
);
LinkViewerCloud.displayName = "LinkViewerCloud";
