import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PlayerClass } from "./PlayerClass";
import { SizingType } from "../../../../store/graphqlTypes";
import { Logger } from "../../../../logger/logger";
import { generateDomId } from "./utils";

const log = new Logger("SimpleVideoViewer");

export interface SimpleVideoViewerProps {
  id?: string; // dom node id
  style?: CSSStyleDeclaration;
  src: string;
  controls?: boolean;
  poster?: string;
  autoplay?: boolean;
  loop?: boolean;
  muted?: boolean;
  initialPlaybackPositionMs?: number;
  sizeType?: SizingType;
  isPreload: boolean;
}

export const SimpleVideoViewer: FunctionComponent<SimpleVideoViewerProps> = (
  props
) => {
  const {
    id,
    src,
    controls,
    autoplay,
    loop,
    muted,
    initialPlaybackPositionMs,
    sizeType,
    isPreload,
  } = props;

  const [playerInstance, setPlayerInstance] = useState<
    PlayerClass | undefined
  >();

  const domNodeId = useMemo(() => {
    return id ? id : generateDomId();
  }, [id]);

  const onErrorCallback = useCallback(
    (error: Error | MediaError | undefined) => {
      if (error) {
        log.warn({
          message: `Cannot load or play video ${src}, error code ${
            (error as MediaError | undefined)?.code
          }`,
          context: {
            errorMessage: error?.message,
            errorCode: (error as MediaError | undefined)?.code,
          },
          proofOfPlayFlag: true,
        });
      } else {
        log.warn({
          message: `Cannot load or play video ${src}: ${error}`,
          proofOfPlayFlag: true,
        });
      }
    },
    [src]
  );

  useEffect(() => {
    const player = new PlayerClass(
      {
        autoplay,
        container: "#" + domNodeId,
        controls,
        debug: true,
        loop,
        muted,
        onError: onErrorCallback,
      },
      new Logger("PlayerClass")
    );

    setPlayerInstance(player);

    return () => {
      player.destroy();
      setPlayerInstance(undefined);
    };
  }, [domNodeId, src, controls, autoplay, loop, muted, onErrorCallback]);

  useEffect(() => {
    if (playerInstance) {
      playerInstance.load(src);
    }
  }, [playerInstance, src]);

  useEffect(() => {
    if (playerInstance && typeof initialPlaybackPositionMs === "number") {
      playerInstance.seekTo(Math.round(initialPlaybackPositionMs / 1000));
    }
  }, [initialPlaybackPositionMs, playerInstance]);

  useEffect(() => {
    if (playerInstance) {
      if (!isPreload) {
        playerInstance.play();
      }
    }
  }, [isPreload, playerInstance]);

  return (
    <>
      <style id={`video-styles-${domNodeId}`}>
        {`
          #${domNodeId} {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            overflow: hidden;
          }

          #${domNodeId} > video {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: ${sizeType === "fill" ? "cover" : "contain"};
          }
        `}
      </style>
      <div id={domNodeId}></div>
    </>
  );
};
