"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityType = void 0;
require("firebase/firestore");
var EntityType;
(function (EntityType) {
    EntityType["SCREEN"] = "screen";
    EntityType["CHANNEL"] = "channel";
    EntityType["CAST"] = "cast";
    EntityType["PLAYLIST"] = "playlist";
    EntityType["FOLDER"] = "folder";
    EntityType["FILE"] = "file";
    EntityType["FILE_OUTPUT"] = "file_output";
    EntityType["FILE_PROCESSING_JOB"] = "file_processing_job";
    EntityType["LINK"] = "link";
    EntityType["SITE"] = "site";
    EntityType["APP"] = "app";
    EntityType["APP_INSTALL"] = "app_install";
    EntityType["APP_INSTANCE"] = "app_instance";
    EntityType["TEAM"] = "team";
    EntityType["USER"] = "user";
    EntityType["EMAIL"] = "email";
    EntityType["LAYOUT"] = "layout";
    EntityType["SPACE"] = "space";
    EntityType["THEME"] = "theme";
})(EntityType = exports.EntityType || (exports.EntityType = {}));
