/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { createAppThemeWithFontFaceDataURI } from "@screencloud/studio-custom-fonts";
import { App } from "../../../../store/apps/types";
import { PlayerState } from "../../../../store/rootReducer";
import styles from "./AppViewer.module.css";
import {
  AppTokenPayload,
  GenericMessage,
  InitializeMessagePayload,
  PMIMessageReceivedPayload,
} from "./types";
import { Theme } from "../../../../store/themes/types";
import { ScreenData, ScreenState } from "../../../../store/screen/types";
import { ContextConfig, DeviceConfig } from "../../../../store/config/types";
import { PlayerFile } from "../../../../store/files/types";
import { useManualQuery } from "graphql-hooks";
import { ConfigurationManager } from "../../../../configurationManager";
import { CREATE_APP_TOKEN } from "../../../../store/graphqlQueries";
import { DEFAULT_APP_INSTANCE_ID } from "../../../../constants";
import {
  makeConnectedMessage,
  makeInitializeMessage,
  makeStartMessage,
  makeTokenUpdatedMessage,
  sendMessage,
} from "./utils";
import { Maybe } from "../../../../queries";
import { ContentFailureGenericCb } from "../TimelineViewer/types";
import {
  Logger,
  LogLevel,
  LogMessage,
  LogObjects,
} from "../../../../logger/logger";
import { AudioSettings } from "../../../../providers/AudioSettingsProvider/types";
import { AudioSettingsContext } from "../../../../providers/AudioSettingsProvider/AudioSettingsProvider";

export const log = new Logger("appViewer");
interface AppViewerProps {
  app?: App;
  overrideAppInitialize?: Partial<InitializeMessagePayload>;
  theme?: Theme;
  screenData?: ScreenData;
  orgId?: string;
  screenId?: string;
  spaceId?: string;
  filesByAppInstanceId: {
    nodes: Array<PlayerFile>;
  };
  fullDurationMs: number;
  isPreload?: boolean;
  contextConfig: ContextConfig;
  isPreview: boolean;
  device?: DeviceConfig;
  initialPlaybackPositionMs: number;
  durationElapsedMs: number;
  featureFlags?: Maybe<string>[];
  onContentFailure: ContentFailureGenericCb;
  region: string;
  appViewerToken?: string;
  additionalContextKey?: string;
}

type RequestAuthTokenListener = ({
  data,
  requestId,
}: PMIMessageReceivedPayload) => void;

// The AppViewer is exported for reuse in the SiteViewerContainer.
// This will no longer need to be exported after Secure Sites has been refactored to use App Instances under the hood.
export const AppViewer = (
  props: AppViewerProps
): ReactElement<AppViewerProps> => {
  const {
    app,
    overrideAppInitialize,
    theme,
    screenData,
    orgId,
    screenId,
    spaceId,
    filesByAppInstanceId,
    fullDurationMs,
    contextConfig,
    isPreview,
    device,
    durationElapsedMs,
    featureFlags,
    region,
    onContentFailure,
    appViewerToken,
    additionalContextKey,
  } = props;
  // TODO - How does memo() interact with this ref? When does it reset?
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const viewerUrl = app?.viewerUrl || overrideAppInitialize?.viewerUrl || "";
  const isTesting = process.env.NODE_ENV === "test";

  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const audioSettings = useContext<AudioSettings>(AudioSettingsContext);
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const [additionalQrKey, setAddtionalQrKey] = useState("");
  const [fetchAppToken] = useManualQuery<{
    createSignedRuntimeJwt: AppTokenPayload;
  }>(CREATE_APP_TOKEN, {
    useCache: false,
    skipCache: true,
    variables: {
      input: {
        name: app?.name || "",
        appId: app?.id || "",
        appInstanceId:
          overrideAppInitialize?.appInstanceId || DEFAULT_APP_INSTANCE_ID,
        screenId: screenId || "",
      },
    },
  });

  useEffect(() => {
    setAddtionalQrKey(additionalContextKey ?? "");
  }, [additionalContextKey]);

  const requestAppToken = useCallback(async (): Promise<
    AppTokenPayload | undefined
  > => {
    try {
      const response = await fetchAppToken();
      return (response.data as {
        createSignedRuntimeJwt: AppTokenPayload;
      }).createSignedRuntimeJwt;
    } catch (e) {
      log.error({
        message: "fetchAppToken failed",
        context: {
          message: (e as Error)?.message,
        },
      });
      return undefined;
    }
  }, [fetchAppToken]);

  useEffect(() => {
    const onMessage = (event: MessageEvent): void => {
      try {
        const { data, source } = event;
        // There may be multiple AppViewers on screen at once, e.g. multiple zones.
        if (!isTesting && source !== iframeRef.current?.contentWindow) {
          return;
        }

        // Only CONNECT, CONNECT_SUCCESS, DISCONNECT messages add this ___ thing.
        // The rest nest their data under a 2nd "data" key.
        // TODO - Remove this complexity.
        if (data.substr(0, 3) === "___") {
          const parsed = JSON.parse(data.substring(3));
          handleMessage(parsed);
        } else {
          const parsed = JSON.parse(data);
          handleMessage(parsed.data, parsed.requestId);
        }
      } catch (err) {
        log.warn({
          message: "Could not parse received postMessage",
          context: {
            err: (err as Error)?.message,
          },
          proofOfPlayFlag: true,
        });
      }
    };

    /**
     * 1 - Receive all messages from this app.
     */
    window.addEventListener("message", onMessage, false);
    /**
     * 2 - Deal with the messages.
     */
    const handleMessage = async (
      receivedMessage: GenericMessage,
      requestId?: number
    ): Promise<void> => {
      if (!receivedMessage) return;
      const replyMessages: GenericMessage[] = [];
      switch (receivedMessage.type) {
        case "CONNECT": {
          replyMessages.push(makeConnectedMessage());
          // send theme to check if bodyfont and headingfont is font url them return url as data uri
          const modifiedTheme = createAppThemeWithFontFaceDataURI(theme);
          if (app) {
            replyMessages.push({
              ...makeInitializeMessage(
                app,
                region,
                filesByAppInstanceId,
                modifiedTheme,
                orgId,
                screenId,
                spaceId,
                screenData,
                fullDurationMs,
                contextConfig,
                device,
                durationElapsedMs,
                appViewerToken,
                additionalQrKey,
                screen.isMuted || audioSettings.shouldMuteMedia,
                screen.playerWidth!,
                screen.playerHeight!,
                screen.playerTimezone!,
                screen.timezoneOverride ?? ""
              ),
              requestId,
            });
          } else if (overrideAppInitialize) {
            replyMessages.push({
              type: "initialize",
              payload: {
                ...overrideAppInitialize,
                context: {
                  ...contextConfig,
                  theme: modifiedTheme,
                  screenData,
                  appViewerToken,
                  session: {
                    id: additionalQrKey,
                  },
                },
                durationElapsedMs,
                featureFlags,
              },
              requestId,
            });
          }
          break;
        }
        case "initialized":
          setIsAppInitialized(true);
          break;
        case "started":
          // update to debug to reduce the amount of cost and a bit redundant with "Show App" event
          log.debug({
            message: `Player received app "started" confirmation for[app-${app?.name}]`,
            context: {
              name: app?.name,
              appid: app?.id,
              contentType: "app",
              isPreview: isPreview,
              isPreload: props.isPreload,
              hasAppViewerToken: !!appViewerToken,
              viewerUrl: viewerUrl,
            },
          });
          break;
        case "requestAuthToken":
          log.info({
            message: `Auth token requested for [app-${app?.name}]`,
            context: {
              appname: app?.name,
              contentType: "app",
              isPreview: isPreview,
              isPreload: props.isPreload,
            },
          });

          // if playing in a preview in editor apps
          // the request auth token has to come from studio as unsaved editor apps don't have an app instance yet
          if (isPreview) {
            ConfigurationManager.getInstance()
              .getRemoteApi()
              .remote.fire("requestAuthToken", { requestId });
          } else {
            const appTokenPayload = await requestAppToken();
            if (iframeRef.current) {
              replyMessages.push({
                type: "requestAuthToken",
                payload: { authToken: appTokenPayload?.signedRuntimeToken },
                requestId,
              });
            }
          }

          break;
        case "playerlog":
          if (receivedMessage?.payload?.message) {
            const logContext = receivedMessage?.payload?.context || {};
            const logMessage = `${receivedMessage?.payload?.message}`;
            const logObject: LogMessage | LogObjects = !receivedMessage?.payload
              ?.context
              ? logMessage
              : { message: logMessage, context: logContext };
            // TODO: verify this bit still working ok
            console.log("logObject = ", logObject);
            console.log("REMOVE ME BEFORE PROD");

            switch (receivedMessage?.payload?.level) {
              case LogLevel.Debug:
                log.debug(logObject);
                break;
              case LogLevel.Info:
                log.info(logObject);
                break;
              case LogLevel.Warning:
                log.warn(logObject);
                break;
              case LogLevel.Error:
                log.error(logObject);
                break;

              default:
                log.debug(logObject);
                break;
            }
          } else {
            console.warn(
              "Invalid Message Format, please check required params.",
              receivedMessage
            );
          }
          break;
      }

      replyMessages.forEach((message) => {
        if (iframeRef.current) {
          if (app || overrideAppInitialize) {
            const { requestId, ...messageToSend } = message;
            try {
              sendMessage(
                iframeRef.current,
                messageToSend,
                viewerUrl,
                requestId
              );
            } catch (error) {
              /// do nothing
              console.warn("can not postMessage", error);
            }
          }
        }
      });
    };

    /**
     * Clean up when the App is removed from screen.
     */
    return (): void => {
      log.debug({
        message: `AppViewer - removeEventListener.`,
        context: {
          appId: app?.id,
          viewerUrl: app?.viewerUrl,
          name: app?.name,
          appInstallId: app?.appInstallId,
          contentType: "app",
          isPreview: isPreview,
          isPreload: props.isPreload,
        },
      });
      window.removeEventListener("message", onMessage, false);
    };
  }, [
    app,
    overrideAppInitialize,
    theme,
    screenData,
    screenId,
    orgId,
    filesByAppInstanceId,
    fullDurationMs,
    props.isPreload,
    viewerUrl,
    contextConfig,
    requestAppToken,
    spaceId,
    isPreview,
    device,
    durationElapsedMs,
    featureFlags,
    region,
    isTesting,
    appViewerToken,
    additionalQrKey,
    screen.isMuted,
    audioSettings.shouldMuteMedia,
    screen.playerWidth,
    screen.playerHeight,
    screen.playerTimezone,
    screen.timezoneOverride,
  ]);

  useEffect(() => {
    // send the started message if the app changes from preload to current
    if (iframeRef.current && isAppInitialized && !props.isPreload) {
      sendMessage(iframeRef.current, makeStartMessage(), viewerUrl);
    }
  }, [props.isPreload, isAppInitialized, viewerUrl]);

  useEffect(() => {
    // send the new token down to the app
    if (appViewerToken && isAppInitialized && iframeRef.current) {
      sendMessage(
        iframeRef.current,
        makeTokenUpdatedMessage({ appViewerToken }),
        viewerUrl
      );
    }
  }, [appViewerToken, isAppInitialized, viewerUrl]);

  useEffect(() => {
    let listener: RequestAuthTokenListener | undefined = undefined;
    if (isPreview) {
      listener = ({ data, requestId }: PMIMessageReceivedPayload) => {
        if (iframeRef.current) {
          sendMessage(
            iframeRef.current,
            {
              type: "requestAuthToken",
              payload: data,
            },
            viewerUrl,
            requestId
          );
        }
      };

      ConfigurationManager.getInstance()
        .getRemoteApi()
        .remote.on("SP_SEND_REQUEST_AUTH_TOKEN", listener);
    }

    return () => {
      if (listener) {
        ConfigurationManager.getInstance()
          .getRemoteApi()
          .remote.off("SP_SEND_REQUEST_AUTH_TOKEN", listener);
      }
    };
  }, [viewerUrl, isPreview]);

  const onError = useCallback(
    (event: SyntheticEvent<HTMLIFrameElement>) => {
      // this is not of much use, as iframes of a different origin do not expose any meaningful details about errors.
      log.error({
        message: "AppViewer iframe error",
        context: {
          viewerUrl,
          appName: app?.name,
          appInstanceId: app?.id,
        },
      });
      onContentFailure();
    },
    [viewerUrl, app?.id, app?.name, onContentFailure]
  );

  /**
   * Cleanup
   */
  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      // note: to reduce the number of log in DataDog, this better to be tracked during development
      log.debug({
        message: `Unmount called for app.[app-${app?.name}]`,
        context: {
          name: app?.name,
          contentType: "app",
          isPreview: isPreview,
          isPreload: props.isPreload,
        },
      });
    };
    // Disable eslint because we want this to be called on unmount only
    // todo: solve the possible outdated information in the log line above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO remove this hook and fix app  multiple rerender
    log.info({
      message: `Show App [app-${app?.name}]`,
      context: {
        viewUrl: app?.viewerUrl,
        name: app?.name,
        contentType: "app",
        isPreview: isPreview,
        isPreload: props.isPreload,
      },
    });
    // Ignore eslint because we want to send log lines only if app id or isPreload status change
    // todo: solve the possible outdated information problem in the log line above
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id, props.isPreload]);

  return (
    <>
      <iframe
        data-testid="app-viewer-iframe"
        ref={iframeRef}
        className={styles.iframe}
        title={app?.name || ""}
        src={viewerUrl}
        onError={onError}
      />
    </>
  );
};

AppViewer.displayName = "AppViewer";
