import { produce } from "immer";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import {
  DefaultDurations,
  GqlContentListItem,
  GqlZoneItemSizeType,
  GqlZoneItemTransition,
} from "../graphqlTypes";
import { REQUEST_SCREEN_SUCCESS } from "../screen/types";
import { PlayerAction } from "../storeTypes";
import {
  ChannelZoneContentItem,
  ContentItemLeaf,
  ContentItemPlaylist,
  ContentListsState,
} from "./types";
import {
  doesContentListBelongToChannel,
  flattenNestedContentItems,
  getDefaultDurationsForItem,
  getItemAvailability,
  getItemDuration,
  getItemSizeType,
  getPreloadDuration,
  makeContentListIdForZone,
} from "./utils";
import { REQUEST_CHANNEL_SUCCESS } from "../channels/types";
import {
  NormalizedPlaylistFragment,
  REQUEST_PLAYLIST_SUCCESS,
} from "../playlists/types";
import { notUndefinedOrNull } from "../../utils/helpers";
import { getDocumentPages, getItemFullDuration } from "../../utils";
import {
  NormalizedAppInstanceFragment,
  REMOVE_APP_INSTANCE,
  RemoveAppInstanceAction,
} from "../apps/types";
import { FileFragment } from "../../queries";
import { NormalizedLinkFragment } from "../links/types";

/**
 * Data about the available app instances.
 */
const initialState: ContentListsState = {
  byId: {},
};

export function contentListsReducer(
  state = initialState,
  action: PlayerAction
): ContentListsState {
  return produce(state, (draft) => {
    switch (action.type) {
      case REQUEST_PLAYLIST_SUCCESS:
      case REQUEST_SCREEN_SUCCESS:
      case REQUEST_CHANNEL_SUCCESS: {
        const addContentListEntity = (
          listId: string,
          listItems: ContentItemLeaf[],
          publishedAt: string,
          nestedPlaylistIds?: string[]
        ): void => {
          const currentList = draft.byId[listId];
          const currentListItems = currentList?.items;
          const isItemsEqual = isEqual(listItems, currentListItems);
          const isPublishedAtEqual = publishedAt === currentList?.publishedAt;

          // New items will trigger elements to re-render, so only update if content has changed.
          if (!isItemsEqual || !isPublishedAtEqual) {
            draft.byId[listId] = {
              ...currentList,
              id: listId,
              items: isItemsEqual ? currentListItems : listItems,
              publishedAt,
              nestedPlaylistIds,
            };
          }
        };

        const fileEntities = action.payload.files;
        const appEntities = action.payload.apps;
        /**
         * Playlists cannot embed other playlists right now.
         */
        const playlists = Object.values(action.payload.playlists);
        const playlistItemsById: {
          [id: string]: ContentItemLeaf[];
        } = {};

        playlists.forEach((playlist) => {
          const listId = playlist.id;
          const filteredPlaylistList = filterUnavailableItems(
            playlist.content.list,
            action.payload.apps,
            action.payload.files,
            action.payload.links,
            action.payload.playlists
          );

          const items = makeContentItemsList(
            filteredPlaylistList,
            {},
            fileEntities,
            appEntities,
            playlist.content.props?.default_durations
          );

          playlistItemsById[listId] = items;

          addContentListEntity(listId, items, playlist.publishedAt);
        });

        /**
         * When used in a Channel (or another playlist), replace the Playlist reference with its contents.
         * i.e. removing list nesting upfront, so runtime is easier.
         *
         * TODO - This could be done server-side, so playlists as a concept are just a Studio UI helper?
         */
        if (action.type !== REQUEST_PLAYLIST_SUCCESS) {
          const channels = Object.values(action.payload.channels);

          channels.forEach((channel) => {
            const zones = channel.content?.zones;
            const activeZoneIds = zones ? Object.keys(zones) : [];
            const defaultDurations = channel.content?.props?.default_durations;
            activeZoneIds.forEach((zoneId) => {
              const zone = zones[zoneId];
              const zoneList = zone?.list;

              if (!zone || !zoneList) {
                return;
              }

              const listId = makeContentListIdForZone(
                channel.layoutByChannel,
                zoneId
              );

              const filteredZoneList = filterUnavailableItems(
                zoneList,
                action.payload.apps,
                action.payload.files,
                action.payload.links,
                action.payload.playlists
              );

              const items = makeContentItemsList(
                filteredZoneList,
                playlistItemsById,
                action.payload.files,
                action.payload.apps,
                defaultDurations,
                zone.props?.sizing_type,
                zone.props?.transition
              );

              const nestedPlaylistIds = compact([
                ...new Set(
                  items
                    .filter((item) => item.parent?.type === "playlist")
                    .map((playlist) => playlist.parent?.id)
                ),
              ]);

              addContentListEntity(
                listId,
                items,
                channel.publishedAt,
                nestedPlaylistIds.length ? nestedPlaylistIds : undefined
              );
            });

            const activeContentListIds = activeZoneIds.map((zoneId) =>
              makeContentListIdForZone(channel.layoutByChannel, zoneId)
            );
            const unusedChannelContentLists = Object.keys(draft.byId).filter(
              (contentListId) =>
                doesContentListBelongToChannel(channel, contentListId) &&
                !activeContentListIds.includes(contentListId)
            );
            unusedChannelContentLists.forEach((contentListId) => {
              delete draft.byId[contentListId];
            });
          });
        }

        break;
      }
      case REMOVE_APP_INSTANCE: {
        Object.keys(draft.byId).forEach((contentListId) => {
          const targetContentList = draft.byId[contentListId];
          function getTargetIndex(
            targetAction: RemoveAppInstanceAction
          ): number {
            return targetContentList.items.findIndex((item) => {
              return item.type === "app" && item.id === targetAction.payload.id;
            });
          }
          let targetIndex = getTargetIndex(action);

          // extra protection from infinite loops
          const maxIterations = targetContentList.items.length;
          let iterationCounter = 0;

          while (targetIndex > -1 && iterationCounter <= maxIterations) {
            targetContentList.items.splice(targetIndex, 1);
            targetIndex = getTargetIndex(action);

            iterationCounter++;
          }
        });
      }
    }

    return draft;
  });
}

/**
 * Filters out content items form inputList that are not accessible anymore. Returns filtered list.
 *
 * We assume here: the item in the content list json is available, then actual entity is going to be sent in the payload
 * of the same action. If whenever graphql request is not going to include all the referenced entities (eg someone
 * decides do it in several separate smaller queries) - this filter will not work correctly.
 */
const filterUnavailableItems = (
  inputList: GqlContentListItem[],
  apps: { [key: string]: NormalizedAppInstanceFragment },
  files: { [key: string]: FileFragment },
  links: { [key: string]: NormalizedLinkFragment },
  playlists: { [key: string]: NormalizedPlaylistFragment }
): GqlContentListItem[] => {
  const isItemAvailable = (item: GqlContentListItem): boolean => {
    if (item.content._ref.type === "file") {
      return !!files[item.content._ref.id];
    }

    if (item.content._ref.type === "app") {
      return !!apps[item.content._ref.id];
    }

    if (item.content._ref.type === "link") {
      return !!links[item.content._ref.id];
    }

    if (item.content._ref.type === "playlist") {
      return !!playlists[item.content._ref.id];
    }

    return true;
  };

  return inputList.filter(isItemAvailable);
};

const makeContentItemsList = (
  items: GqlContentListItem[],
  nestedPlaylistContentLists: { [id: string]: ContentItemLeaf[] },
  files: { [id: string]: FileFragment },
  apps: { [id: string]: NormalizedAppInstanceFragment },
  defaultDurations: DefaultDurations | undefined, // these must come from the parent channel duration settings
  sizeTypes?: GqlZoneItemSizeType, // these must come from the parent channel size type settings
  transition?: GqlZoneItemTransition
): ContentItemLeaf[] => {
  const list = items
    .map<ChannelZoneContentItem | undefined>((item) => {
      if (item.content._ref.type === "playlist") {
        const contentItem: ContentItemPlaylist = {
          id: item.content._ref.id,
          type: item.content._ref.type,
          rules: item.rules,
          ...getItemAvailability(item, files),
          listId: item.list_id,
          defaultDurations: getDefaultDurationsForItem(
            item.content,
            defaultDurations
          ),
          defaultSizeTypes: sizeTypes,
          transition,
        };
        return contentItem;
      } else if (
        item.content._ref.type === "app" ||
        item.content._ref.type === "file" ||
        item.content._ref.type === "link" ||
        item.content._ref.type === "site"
      ) {
        let availableAt = null;
        let expireAt = null;
        let documentPageLength = 1;
        let mimetype = undefined;
        let itemEntity = undefined;

        if (item.content._ref.type === "file") {
          itemEntity = files[item.content._ref.id];
          if (itemEntity) {
            mimetype = itemEntity.mimetype;
            documentPageLength = getDocumentPages(itemEntity).length;
            availableAt = itemEntity.availableAt;
            expireAt = itemEntity.expireAt;
          }
        } else if (item.content._ref.type === "app") {
          itemEntity = apps[item.content._ref.id];
          mimetype = "app";
          availableAt = itemEntity.availableAt;
          expireAt = itemEntity.expireAt;
        }

        const declaredDuration: number | undefined = getItemDuration(
          item,
          itemEntity
        );

        const contentItem: ContentItemLeaf = {
          id: item.content._ref.id,
          type: item.content._ref.type,
          durationMs: getItemFullDuration(
            declaredDuration,
            item.content._ref.type,
            defaultDurations,
            mimetype,
            documentPageLength
          ),
          sizeType: getItemSizeType(
            item.content._ref.type,
            sizeTypes,
            mimetype
          ),
          transition,
          preloadDurationMs: getPreloadDuration(item),
          listId: item.list_id,
          rules: item.rules,
          availableAt,
          expireAt,
        };

        return contentItem;
      }

      return undefined;
    })
    .filter(notUndefinedOrNull);

  return flattenNestedContentItems(list, nestedPlaylistContentLists, files);
};
