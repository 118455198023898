import { ExposedInterface } from "@screencloud/postmessage-interface";

import healthCheck from "./healthCheck";

class StudioPlayerExposedInterface {
  private isExposed: boolean;

  constructor() {
    this.isExposed = false;
  }

  expose(): void {
    if (this.isExposed) {
      return; // should expose once
    }

    new ExposedInterface({
      healthcheck: healthCheck.getLatestHealthCheck.bind(healthCheck),
    });

    this.isExposed = true;
  }
}

const studioPlayerExposedInterface = new StudioPlayerExposedInterface();
export default studioPlayerExposedInterface;
