import { PlayerState } from "../rootReducer";
import { PlayerAction, TraversalResult } from "../storeTypes";
import { CLEANUP_STALE_ENTITIES } from "./types";
import { getRootContent } from "../../utils/rootContent";
import { produce } from "immer";
import { traverseEntity } from "../normalizationUtils";

/**
 * This reducer checks the entire existing data set in the store and removes unused entities.
 * Unused entities may stay in the store due to normalization and live updates solutions: when any data is loaded from
 * graphql - it's normalized and added to existing data in the store.
 */
export function cleanupReducer(
  state: PlayerState,
  action: PlayerAction
): PlayerState {
  switch (action.type) {
    case CLEANUP_STALE_ENTITIES: {
      return cleanupStaleEntities(state);
    }

    default:
      return state;
  }
}

function cleanupStaleEntities(state: PlayerState): PlayerState {
  return produce(state, (draft) => {
    const rootContent = getRootContent(state.config.contentConfig);

    if (
      rootContent.type === "void" ||
      rootContent.type === "editor" ||
      rootContent.type === "site" ||
      rootContent.type === "link" ||
      rootContent.type === "file" ||
      rootContent.type === "app"
    ) {
      // single item assigned to player means there is not nested entities to track and cleanup
      return;
    }

    const traversedEntities = traverseEntity(
      rootContent.type,
      rootContent.id,
      state
    );

    Object.keys(traversedEntities).forEach((key) => {
      switch (key as keyof TraversalResult) {
        case "apps":
          Object.keys(draft.apps.byId).forEach((appId) => {
            if (!traversedEntities.apps.has(appId)) {
              delete draft.apps.byId[appId];
            }
          });
          break;
        case "channels":
          Object.keys(draft.channels.byId).forEach((channelId) => {
            if (!traversedEntities.channels.has(channelId)) {
              delete draft.channels.byId[channelId];
            }
          });
          break;
        case "files":
          Object.keys(draft.files.byId).forEach((fileId) => {
            if (!traversedEntities.files.has(fileId)) {
              delete draft.files.byId[fileId];
            }
          });
          break;

        case "links":
          Object.keys(draft.links.byId).forEach((linkId) => {
            if (!traversedEntities.links.has(linkId)) {
              delete draft.links.byId[linkId];
            }
          });
          break;

        case "playlists":
          Object.keys(draft.playlists.byId).forEach((playlistId) => {
            if (!traversedEntities.playlists.has(playlistId)) {
              delete draft.playlists.byId[playlistId];
            }
          });
          break;

        case "sites":
          Object.keys(draft.sites.byId).forEach((siteId) => {
            if (!traversedEntities.sites.has(siteId)) {
              delete draft.sites.byId[siteId];
            }
          });
          break;

        case "spaces":
          Object.keys(draft.spaces.byId).forEach((spaceId) => {
            if (!traversedEntities.spaces.has(spaceId)) {
              delete draft.spaces.byId[spaceId];
            }
          });
          break;

        case "themes":
          Object.keys(draft.themes.byId).forEach((themeId) => {
            if (!traversedEntities.themes.has(themeId)) {
              delete draft.themes.byId[themeId];
            }
          });
          break;

        case "layouts":
          Object.keys(draft.layouts.byId).forEach((layoutId) => {
            if (!traversedEntities.layouts.has(layoutId)) {
              delete draft.layouts.byId[layoutId];
            }
          });
          break;

        case "contentLists":
          Object.keys(draft.contentLists.byId).forEach((contentListId) => {
            if (!traversedEntities.contentLists.has(contentListId)) {
              delete draft.contentLists.byId[contentListId];
            }
          });
          break;

        default:
          throw new Error(`Unknown traversal result key: ${key}`);
      }
    });
  });
}
