import { canPlayUnmutedMedia } from "../../../../utils/autoplay";

export function generateDomId(): string {
  return (
    "p-" +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function isMediaPlaying(mediaElement: HTMLMediaElement): boolean {
  return !!(
    mediaElement &&
    mediaElement.currentTime > 0 &&
    !mediaElement.paused &&
    !mediaElement.ended &&
    mediaElement.readyState > 2
  );
}

export function getMutedStatus(isScreenMuted: boolean) {
  const muted = isScreenMuted || !canPlayUnmutedMedia();
  return muted;
}
