import { ContentItemLeaf } from "../../store/contentLists/types";
import { TimelineItem, TimelineItemContent } from "../../store/timelines/types";
import { isTimelineItemVoid } from "../../utils/contentItemTypeGuards";

/**
 * Produces timeline item, makes sure all the necessary props are applied.
 * This method should be used any time you need to produce a timeline item, don't construct timeline items manually.
 */
export function produceTimelineItem(
  contentListItem: ContentItemLeaf,
  startTimestampMs: number,
  isInfinite: boolean,
  showDurationMs: number,
  breakpointId: number
): TimelineItemContent {
  const isFullscreen = contentListItem.rules?.some((rule) => rule.full_screen);
  const isPrimaryAudio = contentListItem.rules?.some(
    (rule) => rule.primary_audio
  );

  return {
    id: contentListItem.id,
    type: contentListItem.type,
    preloadDurationMs: contentListItem.preloadDurationMs,
    fullDurationMs: contentListItem.durationMs,
    listId: contentListItem.listId,
    transition: contentListItem.transition,
    sizeType: contentListItem.sizeType,
    isFullscreen,
    isPrimaryAudio,
    startTimestampMs,
    isInfinite,
    showDurationMs,
    breakpointId,
  };
}

export const getTotalLoopItems = (
  isFullLoopTimelineItemsRequireMerge: boolean,
  bpFullDuration: number,
  startSegmentDurationMs: number,
  validItemsTotalDurationMs: number,
  validItemsCount: number
): number => {
  const bpFulldurationAfterStartSegment =
    bpFullDuration - startSegmentDurationMs;
  const maxFullLoopRepeat =
    bpFulldurationAfterStartSegment > 0
      ? Math.floor(bpFulldurationAfterStartSegment / validItemsTotalDurationMs)
      : 0;

  // the full loop that have start and end with the same content, require merge
  // equation to get the generated loop item count with merge tail+head during join
  // so the equation is (loopItemsCount * n) - (n - 1)
  // Ex. [1,2,3,1,1,2,3,1,1,2,3,1] => [1,2,3,1,2,3,1,2,3,1]
  // maxFullLoopRepeat = 3, validItemCount = 4
  return isFullLoopTimelineItemsRequireMerge && maxFullLoopRepeat > 0
    ? maxFullLoopRepeat * validItemsCount - (maxFullLoopRepeat - 1)
    : maxFullLoopRepeat * validItemsCount;
};

/** To join two timeline together with automatic merge duplicate item, or both are void */
export function joinTimeline(
  first: TimelineItem[] = [],
  second: TimelineItem[] = []
): TimelineItem[] {
  const lastItemInFirst = first[first.length - 1];
  const firstItemInSecond = second[0];
  if (
    lastItemInFirst &&
    firstItemInSecond &&
    ((!isTimelineItemVoid(lastItemInFirst) &&
      !isTimelineItemVoid(firstItemInSecond) &&
      lastItemInFirst.id === firstItemInSecond.id) ||
      (isTimelineItemVoid(lastItemInFirst) &&
        isTimelineItemVoid(firstItemInSecond)))
  ) {
    // update last show item duration to cover the one that duplicate and will be removed
    // bear in mind that "showDurationMs" can greater than original item's "fullDurationMs" for this case
    lastItemInFirst.showDurationMs =
      lastItemInFirst.showDurationMs + firstItemInSecond.showDurationMs;
    // remove the duplicate one
    second.splice(0, 1);
  }
  first.push(...second);
  return first;
}
