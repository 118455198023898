import { getLogger } from "../utils/debugLog";
import {
  convertDateToLocalTimezone,
  getDateIsoShort,
  getEndOfDay,
  getStartOfDay,
  getTimestampByTime,
  parseDateInLocalTime,
  targetSpacetime,
  TimeOptions,
  getOverrideTimeZoneOffset,
  getStartOfDayWithTimezoneOffset,
  getEndOfDayWithTimezoneOffset,
  ParsedDate,
  playbackNowTimestamp,
} from "./timeManager";
import sortedUniq from "lodash/sortedUniq";
import { BreakpointWithItems, FilteringMemo } from "../store/playback/types";
import { ContentItemLeaf } from "../store/contentLists/types";
import { DAY_DURATION_MS } from "../constants";
import { WEEK_DAYS, WeekDay } from "./scheduleFilterConstants";
import { ScheduleFilterCache } from "./scheduleFilterCache";
import {
  TimelinePlayback,
  TimelinePlaybackMode,
} from "../store/timelines/types";

const log = getLogger("schedule-filter:error");

export type DATETIME = string;

export interface WithAvailabilityRules {
  rules: ScheduleRules[] | undefined;
  availableAt: DATETIME | null;
  expireAt: DATETIME | null;
}

export interface ScheduleRules {
  time?: Array<{ start: string; end: string }>;
  day_of_week?: { [key in WeekDay]: boolean };
  date?: Array<{
    start: string | undefined;
    end: string | undefined;
  }>;
  exclusive?: boolean;
  specific_date?: boolean;
  full_screen?: boolean;
  primary_audio?: boolean;
}

export type ScheduleFilterResult = boolean[];

export interface ScheduleFilterReport {
  result: ScheduleFilterResult;
  validItemsCount?: number;
  filteredListFullDurationMs?: number;
}

// local temporary cache, store key format as `DATE_TIMEZONE`
let localCacheTimezoneOffsets: { [key: string]: number } = {};

/**
 * Returns a sorted unique list of schedule breakpoints within a target day
 * If neither of items have rules to be applied in future, an empty array is returned
 * Note: breakpoints for recurring rules are calculated in the target period only: target week for weekdays, target
 * day for time ranges
 */
export function getScheduleBreakpoints(
  items: WithAvailabilityRules[],
  targetDateIsoShort: string,
  timeOptions: TimeOptions,
  playbackMode?: TimelinePlaybackMode
): number[] {
  // scope of interesting in time of just ONE DAY = START_DAY to END_DAY
  const targetDateSpacetime = targetSpacetime(timeOptions, targetDateIsoShort);
  let timelineStartTimestamp = getStartOfDay(timeOptions, targetDateIsoShort);
  const timelineEndTimestamp = timelineStartTimestamp + DAY_DURATION_MS - 1; // day end is 23:59:59:999
  const breakpointsSet: Set<number> = new Set<number>();
  const weekRuleBreakPoints: Set<number> = new Set<number>();

  if (
    playbackMode &&
    playbackMode.type === TimelinePlayback.TIMELINE_PLAYBACK_UNSYNC
  ) {
    // playback with unsync mode will set start of day time to the current device time
    timelineStartTimestamp = playbackNowTimestamp(timeOptions);
  }

  // this is for manual memoization purpose to avoid any unnecessary calculations
  const memoObject = {
    time: new Set<string>(),
    date: new Set<DATETIME>(),
    weekDay: new Set<WeekDay>(),
    rulesObject: new Set<ScheduleRules[]>(),
  };

  // check item with available and expire
  let availableTimestamp = -Infinity;
  let expireTimestamp = Infinity;

  const addBreakpoint = (ts: number): boolean => {
    // breakpoint is in the item's available period
    // and breakpoint also in the target day period
    if (
      ts >= availableTimestamp &&
      ts <= expireTimestamp &&
      ts >= timelineStartTimestamp &&
      ts <= timelineEndTimestamp
    ) {
      breakpointsSet.add(ts);
      return true;
    }
    return false;
  };

  items.forEach((item) => {
    // default value for non schedule item (everyday + all day)
    availableTimestamp = -Infinity;
    expireTimestamp = Infinity;

    // add break point for availableAt and expireAt
    if (item.availableAt && !memoObject.date.has(item.availableAt)) {
      availableTimestamp = convertDateToLocalTimezone(
        timeOptions,
        item.availableAt
      );
      addBreakpoint(availableTimestamp);
      memoObject.date.add(item.availableAt);
    }

    if (item.expireAt && !memoObject.date.has(item.expireAt)) {
      expireTimestamp = convertDateToLocalTimezone(timeOptions, item.expireAt);
      addBreakpoint(expireTimestamp);
      memoObject.date.add(item.expireAt);
    }

    // check item rules
    if (item.rules && !memoObject.rulesObject.has(item.rules)) {
      item.rules.forEach((rule) => {
        // check item specific date range
        if (rule.date) {
          rule.date.forEach((dateRule) => {
            if (dateRule.start && !memoObject.date.has(dateRule.start)) {
              const dateRuleStartTimestamp = getStartOfDay(
                timeOptions,
                dateRule.start
              );
              addBreakpoint(dateRuleStartTimestamp);
              memoObject.date.add(dateRule.start);
            }

            if (dateRule.end && !memoObject.date.has(dateRule.end)) {
              const endTimestamp = getEndOfDay(timeOptions, dateRule.end);
              addBreakpoint(endTimestamp);
              memoObject.date.add(dateRule.end);
            }
          });
        }

        // check item day of week
        // todo: if item is not valid whole target week - ignore day of week parsing completely
        if (rule.day_of_week && !rule.specific_date) {
          const breakpointDays: number[] = [];

          WEEK_DAYS.forEach((day, index) => {
            const nextDayIndex = index + 1 < WEEK_DAYS.length ? index + 1 : 0;

            if (
              rule.day_of_week &&
              rule.day_of_week[day] !==
                rule.day_of_week[WEEK_DAYS[nextDayIndex]]
            ) {
              breakpointDays.push(index);
            }
          });

          breakpointDays.forEach((dayNumber) => {
            // below is for try to eliminate the breakpoint that too closed to each other (1ms different)
            // adding one millisecond here for breakpoint to match end of day t start of next day
            //  (useful for following dedupe operation and avoiding 1 millisecond time ranges)
            if (!memoObject.weekDay.has(WEEK_DAYS[dayNumber])) {
              const value =
                targetDateSpacetime.day(dayNumber).endOf("day").epoch + 1; // turn 23:59:59:999 to 00:00:00:000
              weekRuleBreakPoints.add(value);
              addBreakpoint(value);
              memoObject.weekDay.add(WEEK_DAYS[dayNumber]);
            }
          });
        }

        // check item times
        if (rule.time) {
          const doesTargetDayFitDateRules = isValidInRuleDateRangeV2(
            timeOptions,
            timelineStartTimestamp,
            rule
          );

          const isValidInAvailableDate = availableTimestamp
            ? availableTimestamp < timelineEndTimestamp
            : true;
          const isValidInExpireDate = expireTimestamp
            ? expireTimestamp > timelineStartTimestamp
            : true;
          const doesTargetDayFitAvailabilityRules =
            isValidInAvailableDate && isValidInExpireDate;

          // parse time rules only if item is active in the target day, skip otherwise
          if (doesTargetDayFitDateRules && doesTargetDayFitAvailabilityRules) {
            rule.time.forEach((timeRule) => {
              if (!memoObject.time.has(timeRule.start)) {
                const timeRuleStartTimestamp = getTimestampByTime(
                  timeOptions,
                  timeRule.start,
                  targetDateIsoShort
                );

                if (
                  timeRuleStartTimestamp > availableTimestamp &&
                  timeRuleStartTimestamp < expireTimestamp
                ) {
                  if (addBreakpoint(timeRuleStartTimestamp)) {
                    memoObject.time.add(timeRule.start);
                  }
                }
              }

              if (!memoObject.time.has(timeRule.end)) {
                const endTimestamp = getTimestampByTime(
                  timeOptions,
                  timeRule.end,
                  targetDateIsoShort
                );

                if (
                  endTimestamp > availableTimestamp &&
                  endTimestamp < expireTimestamp
                ) {
                  if (addBreakpoint(endTimestamp)) {
                    memoObject.time.add(timeRule.end);
                  }
                }
              }
            });
          }
        }
      });

      memoObject.rulesObject.add(item.rules);
    }
  });

  const sortedList = sortedUniq([...breakpointsSet].sort((a, b) => a - b));

  // Since the agreement of timeline generator is to always reset the start playback index
  // at each beginning of the day so below is to ensure to adding the breakpoint of
  // day start and day end timestamps here to ensure cover the reset point.
  if (!sortedList.includes(timelineStartTimestamp)) {
    sortedList.unshift(timelineStartTimestamp);
  }
  if (!sortedList.includes(timelineEndTimestamp)) {
    sortedList.push(timelineEndTimestamp);
  }

  return sortedList;
}

export function generateItemsForBreakpoints(
  contentList: ContentItemLeaf[],
  timeOptions: TimeOptions,
  targetDateIso: string,
  playbackMode?: TimelinePlaybackMode
): BreakpointWithItems[] {
  const scheduleFilterCache = ScheduleFilterCache.getInstance();

  if (scheduleFilterCache) {
    const cachedResult = scheduleFilterCache.fetch([
      contentList,
      timeOptions,
      targetDateIso,
    ]);

    if (cachedResult) {
      return cachedResult;
    }
  }

  const breakpointTimestamps = getScheduleBreakpoints(
    contentList,
    targetDateIso,
    timeOptions,
    playbackMode
  );
  const totalBreakpoints = breakpointTimestamps.length;
  const resultBreakpointsWithItems: BreakpointWithItems[] = new Array(
    totalBreakpoints
  );

  let resultBreakpointsWithItemsCountIdx = 0;

  for (const breakpointTimestamp of breakpointTimestamps) {
    let totalDuration = 0;
    let validItemCount = 0;

    const filteredListResultFlags = filteredListWithApplyRulesV2(
      contentList,
      timeOptions,
      breakpointTimestamp
    );

    const contentListWithSatisfyRules = filterSatisfyRules(
      contentList,
      timeOptions,
      breakpointTimestamp
    );

    const items: ContentItemLeaf[] = [];
    validItemCount = 0; // reset item count to reuse variable

    const totalContentList = filteredListResultFlags.length;
    let lastValidItem: ContentItemLeaf | undefined;
    for (let i = 0; i < totalContentList; i++) {
      // if flag as valid then add it to the result
      if (filteredListResultFlags[i]) {
        const contentItem = { ...contentListWithSatisfyRules[i] };
        // sanitize items by merge all adjacent duplicate items
        if (
          lastValidItem &&
          contentItem &&
          lastValidItem.id === contentItem.id
        ) {
          lastValidItem.durationMs += contentItem.durationMs;
        } else {
          items.push(contentItem);
          validItemCount++;
          lastValidItem = contentItem;
        }
        totalDuration = totalDuration + contentItem.durationMs;
      }
    }

    const breakpointWithItems: BreakpointWithItems = {
      breakpointTimestamp: breakpointTimestamp,
      id: resultBreakpointsWithItemsCountIdx,
      items: items,
      totalDurationMs: totalDuration,
      validItemsCount: validItemCount,
    };

    resultBreakpointsWithItems[
      resultBreakpointsWithItemsCountIdx
    ] = breakpointWithItems;
    resultBreakpointsWithItemsCountIdx++;
  }

  if (scheduleFilterCache) {
    scheduleFilterCache.add(
      [contentList, timeOptions, targetDateIso],
      resultBreakpointsWithItems
    );
  }

  return resultBreakpointsWithItems;
}

/**
 * Returns `ContentItemLeaf[]` with *rules* that are satisfied at `breakpointTimestamp`
 */
export function filterSatisfyRules(
  list: ContentItemLeaf[],
  timeOptions: TimeOptions,
  targetTimestamp: number
): ContentItemLeaf[] {
  const breakpointTimeParse = parseDateInLocalTime(
    timeOptions,
    targetTimestamp
  );

  return list.map((item) => ({
    ...item,
    rules: item.rules?.filter((rule) =>
      doesTargetTimeSatisfyRule(
        timeOptions,
        targetTimestamp,
        breakpointTimeParse,
        rule
      )
    ),
  }));
}

export const isValidExclusiveItem = (
  item: WithAvailabilityRules,
  timeOptions: TimeOptions,
  targetTimestamp: number
): boolean => {
  if (!item.rules || item.rules.length === 0) {
    return false;
  }

  const breakpointTimeParse = parseDateInLocalTime(
    timeOptions,
    targetTimestamp
  );

  return item.rules.some(
    (rule) =>
      doesTargetTimeSatisfyRule(
        timeOptions,
        targetTimestamp,
        breakpointTimeParse,
        rule
      ) && rule.exclusive
  );
};

export function filteredListWithApplyRulesV2(
  list: WithAvailabilityRules[],
  timeOptions: TimeOptions,
  breakpointTimestamp: number
): ScheduleFilterResult {
  const breakpointTimeParse = parseDateInLocalTime(
    timeOptions,
    breakpointTimestamp
  );
  localCacheTimezoneOffsets = {}; // reset

  const scheduleFilterResult = list.map(
    (item) =>
      isActive(
        timeOptions,
        item.availableAt || undefined,
        item.expireAt || undefined,
        breakpointTimestamp
      ) &&
      doesTargetTimeSatisfyRules(
        timeOptions,
        item.rules,
        breakpointTimestamp,
        breakpointTimeParse
      )
  );

  const isExclusiveItemPresent = list.some(
    (item, idx) =>
      isValidExclusiveItem(item, timeOptions, breakpointTimestamp) &&
      scheduleFilterResult[idx]
  );

  if (isExclusiveItemPresent) {
    const exclusiveFilterResult = list.map(
      (item, idx) =>
        isValidExclusiveItem(item, timeOptions, breakpointTimestamp) &&
        scheduleFilterResult[idx]
    );
    return exclusiveFilterResult;
  } else {
    return scheduleFilterResult;
  }
}

function doesTargetTimeSatisfyRules(
  timeOptions: TimeOptions,
  rules: ScheduleRules[] | undefined,
  targetTimestamp: number,
  breakpointTimeParse: ParsedDate
): boolean {
  if (!rules || !Array.isArray(rules) || rules.length === 0) {
    return true;
  }

  return rules.some((rule) =>
    doesTargetTimeSatisfyRule(
      timeOptions,
      targetTimestamp,
      breakpointTimeParse,
      rule
    )
  );
}

export function doesTargetTimeSatisfyRule(
  timeOptions: TimeOptions,
  targetTimestamp: number,
  targetTimeParsed: ParsedDate,
  rule: ScheduleRules
): boolean {
  const dateFits = isValidInRuleDateRangeV2(timeOptions, targetTimestamp, rule);

  // return early to avoid unnecessary date calculations
  if (!dateFits) {
    return false;
  }

  const dayOfWeekFits =
    !rule.specific_date && rule.day_of_week
      ? rule.day_of_week[targetTimeParsed.weekDay] ?? true
      : true;

  if (!dayOfWeekFits) {
    return false;
  }

  const timeFits =
    rule.time && rule.time.length > 0
      ? rule.time.some((time) =>
          doesTimeFitTimeRange(
            timeOptions,
            targetTimeParsed,
            time.start,
            time.end
          )
        )
      : true;

  if (!timeFits) {
    return false;
  }

  return true;
}

function isValidInRuleDateRangeV2(
  timeOptions: TimeOptions,
  targetTimestamp: number,
  rule: ScheduleRules
) {
  return rule.date && rule.date.length > 0
    ? !!rule.date.find((dateRange) =>
        doesTimeFitDateRangeV2(
          timeOptions,
          targetTimestamp,
          dateRange.start,
          dateRange.end
        )
      )
    : true;
}

export function doesTimeFitDateRangeV2(
  timeOptions: TimeOptions,
  targetTimestamp: number,
  startDate: string | undefined,
  endDate: string | undefined
): boolean {
  // Create key for store result against DATE + TIMEZONE
  const startDateKey = `${startDate || "void"}-StartOfDay:${
    timeOptions.timezoneOverride || ""
  }`;
  const endDateKey = `${endDate || "void"}-EndOfDay:${
    timeOptions.timezoneOverride || ""
  }`;

  const startDateTimezoneOffset =
    localCacheTimezoneOffsets[startDateKey] ??
    getOverrideTimeZoneOffset(timeOptions, startDate);
  const endDateTimezoneOffset =
    localCacheTimezoneOffsets[endDateKey] ??
    getOverrideTimeZoneOffset(timeOptions, endDate);
  localCacheTimezoneOffsets[startDateKey] = startDateTimezoneOffset;
  localCacheTimezoneOffsets[endDateKey] = endDateTimezoneOffset;

  try {
    const start =
      typeof startDate === "string" && startDate.length > 0
        ? getStartOfDayWithTimezoneOffset(startDate, startDateTimezoneOffset)
        : -Infinity;
    const end =
      typeof endDate === "string" && endDate.length > 0
        ? getEndOfDayWithTimezoneOffset(endDate, endDateTimezoneOffset)
        : Infinity;
    return start <= targetTimestamp && targetTimestamp <= end;
  } catch (e) {
    // todo: notify developers about a invalid value in rule.date
    return false;
  }
}

export function doesTimeFitDateRange(
  timeOptions: TimeOptions,
  targetTimestamp: number,
  startDate: string | undefined,
  endDate: string | undefined
): boolean {
  try {
    const start =
      typeof startDate === "string" && startDate.length > 0
        ? getStartOfDay(timeOptions, startDate)
        : -Infinity;
    const end =
      typeof endDate === "string" && endDate.length > 0
        ? getEndOfDay(timeOptions, endDate)
        : Infinity;
    return start <= targetTimestamp && targetTimestamp <= end;
  } catch (e) {
    // todo: notify developers about a invalid value in rule.date
    return false;
  }
}

function doesTimeFitTimeRange(
  timeOptions: TimeOptions,
  targetTimeParsed: { millisecondFromDayStart: number },
  startTime: string,
  endTime: string
): boolean {
  try {
    const startMs = getMillisecondFromHumanTime(startTime);
    const endMs = getMillisecondFromHumanTime(endTime);

    if (startMs === null || endMs === null) {
      return false;
    }

    return (
      startMs <= targetTimeParsed.millisecondFromDayStart &&
      targetTimeParsed.millisecondFromDayStart < endMs
    );
  } catch (err) {
    log("Can not analyze time rules", err.message);
    return true;
  }
}

/**
 * Returns amount of milliseconds from human input format
 * @param timeInput - hh:mm or hh:mm:ss
 */
function getMillisecondFromHumanTime(timeInput: string): number | null {
  if (
    (timeInput.length !== 5 || timeInput[2] !== ":") &&
    (timeInput.length !== 8 || timeInput[2] !== ":" || timeInput[5] !== ":")
  ) {
    // todo: notify developers about wrong value in json
    // todo: capture in ReoteLogger as warning
    return null;
  }
  const splitValues = timeInput.split(":").map(parseTimeDigits);
  return (
    (splitValues[0] * 60 * 60 + splitValues[1] * 60 + (splitValues[2] ?? 0)) *
    1000
  );
}

function isActive(
  timeOptions: TimeOptions,
  availableAt: DATETIME | undefined,
  expireAt: DATETIME | undefined,
  targetTime: number
): boolean {
  const isAvailable = availableAt
    ? convertDateToLocalTimezone(timeOptions, availableAt) <= targetTime
    : true;

  const isNotExpired = expireAt
    ? convertDateToLocalTimezone(timeOptions, expireAt) > targetTime
    : true;

  return isAvailable && isNotExpired;
}

export function parseTimeDigits(digits: string): number {
  if (digits.length !== 2) {
    throw new Error("Time digits must contain 2 digits.");
  }
  if (digits === "00") {
    return 0;
  } else {
    const result = parseInt(digits, 10);
    if (isNaN(result) || result < 0 || result > 60) {
      throw new Error(`Can not parse input as time digits. Input: ${digits}`);
    }
    return result;
  }
}

/**
 * Produces the filtering results memoization array, it contains the schedule rule timeframes within the 3 day period:
 * previous day - target day - next day. This is done to make sure Filtering periods
 */
export function produceFilteringMemoArray(
  items: WithAvailabilityRules[],
  targetTimestamp: number,
  timeOptions: TimeOptions,
  memoizedScheduleBreakpointsGetter: (targetDateIsoShort: string) => number[]
): FilteringMemo {
  const targetST = targetSpacetime(timeOptions, targetTimestamp);
  const startOfPreviousDay = targetST.startOf("day").epoch - DAY_DURATION_MS;
  const endOfNextDay = startOfPreviousDay + DAY_DURATION_MS * 3;

  const sortedBreakpoints = sortedUniq(
    memoizedScheduleBreakpointsGetter(
      getDateIsoShort(timeOptions, targetTimestamp - DAY_DURATION_MS)
    )
      .concat(
        memoizedScheduleBreakpointsGetter(
          getDateIsoShort(timeOptions, targetTimestamp)
        )
      )
      .concat(
        memoizedScheduleBreakpointsGetter(
          getDateIsoShort(timeOptions, targetTimestamp + DAY_DURATION_MS)
        )
      )
      .filter(
        (breakpoint) =>
          breakpoint > startOfPreviousDay && breakpoint < endOfNextDay
      )
      .sort((a, b) => a - b)
  );

  sortedBreakpoints.push(endOfNextDay);
  sortedBreakpoints.unshift(startOfPreviousDay);

  const result: FilteringMemo = [];

  sortedBreakpoints.forEach((item, idx) => {
    if (idx + 1 < sortedBreakpoints.length) {
      result.push({
        // 1 millisecond is subtracted for a precise no overlap time frame borders in ms
        periodEnd: sortedBreakpoints[idx + 1] - 1,
        periodStart: item,
        result: undefined,
      });
      return;
    }
  });

  return result;
}

/**
 * Returns full duration of a flattened list according to filter results.
 */
export function getFilteredListDuration(
  list: ContentItemLeaf[],
  filterList: ScheduleFilterResult
): number {
  return filterList.reduce<number>((sum, value, idx) => {
    if (!value) {
      return sum;
    }

    const contentItem = list[idx];

    return sum + contentItem.durationMs;
  }, 0);
}
