import {
  DEACTIVATE_SCREEN_MESSAGE,
  PREVIEW_SCREEN_MESSAGE,
  SUSPENDED_SCREEN_MESSAGE,
} from "../../../../constants";
import { OperatingDay } from "../../../../store/graphqlTypes";
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getDeviceTimezoneName,
  getEndOfDay,
  getStartOfDay,
  parseDateInLocalTime,
  TimeOptions,
  playbackNowTimestamp,
} from "../../../../utils/timeManager";
import {
  getScreenActiveOperatingRules,
  isInOperatingHours,
  isLoadedInPairedDevice,
} from "../../../../utils";
import {
  requestScreenSuccess,
  updateScreenDeviceInfoSuccess,
  updateSelfScreenDeviceSuccess,
} from "../../../../store/screen/actions";
import { isEmbedPlayerMode } from "../../../../utils/embedPlayerHelper";
import { useDispatch, useSelector } from "react-redux";
import { useManualQuery } from "graphql-hooks";
import { Alert } from "../../../core/components/Alert/Alert";
import { ConfigState } from "../../../../store/config/types";
import { EntityType } from "@screencloud/signage-firestore-client";
import { GenericViewer } from "../GenericViewer/GenericViewer";
import { LiveUpdateConnector } from "../../../core/containers/LiveUpdatesContainer/LiveUpdateConnector";
import { Loading } from "../../../core/components/Loading/Loading";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenContext } from "./context";
import { ScreenState } from "../../../../store/screen/types";
import { Space } from "../../../../store/spaces/types";
import { useInitialFetch } from "../../../../utils/useInitialFetch";
import { useLiveUpdate } from "../../../../store/liveUpdates/useLiveUpdateHook";
import { useRetry } from "../../../../utils/useRetry";
import { ContextConfig } from "../../../../store/config/types";
import debounce from "lodash/debounce";
import { OrganizationState } from "../../../../store/organization/types";
import { getStudioPlayerVersion } from "../../../../utils/helpers";
import { grqphqlHooksErrorMessage } from "../../../../utils/graphqlHooksErrorMessage";
import {
  screenById,
  ScreenByIdQuery,
  currentScreen,
  CurrentScreenQuery,
  ScreenStatus,
  UpdateSelfScreenDeviceInput,
  useUpdateScreenDeviceInfo,
  useUpdateSelfScreenDevice,
} from "../../../../queries/";
import { Logger } from "../../../../logger/logger";
import { ActiveContentContainer } from "../../../core/containers/ActiveContentContainer/ActiveContentContainer";
import healthCheck from "../../../../utils/pmi/healthCheck";
import RemoteApi from "../../../../remoteApi";

const log = new Logger("screenViewer");
const remoteApi = new RemoteApi();

interface ScreenViewerContainerProps {
  isRoot?: boolean;
}

export const ScreenViewerContainer: FunctionComponent<ScreenViewerContainerProps> = ({
  isRoot,
}: ScreenViewerContainerProps) => {
  const dispatch = useDispatch();
  const screenId = useSelector<PlayerState, string>((state) => state.screen.id);
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const screenStatus = useSelector<PlayerState, ScreenStatus | undefined>(
    (state) => state.screen.status
  );
  const org = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const space = useSelector<PlayerState, Space | undefined>((state) =>
    screen.spaceId ? state.spaces.byId[screen.spaceId] : undefined
  );
  const config = useSelector<PlayerState, ConfigState>((state) => state.config);
  const playbackControlOffset = useSelector<PlayerState, number>(
    (state) => state.playback.controls.timelineOffset
  );
  const timeOptions = useMemo<TimeOptions>(
    () => ({
      timezoneOverride: screen.timezoneOverride ?? "",
      timeOffset: config.timeOffset,
      timelineControlOffset: playbackControlOffset,
    }),
    [screen.timezoneOverride, config.timeOffset, playbackControlOffset]
  );
  const isPreview = useSelector<PlayerState, boolean>(
    (state) => state.screen.isPreview
  );
  const contextConfig = useSelector<PlayerState, ContextConfig>(
    (state) => state.config.contextConfig || {}
  );
  const spaceOperatingHours = space?.preferences?.operating;
  const screenOperatingHours = screen.operatingHours;

  const [isOperating, setIsOperating] = useState<boolean>(
    isInOperatingHours(
      timeOptions,
      undefined,
      screen.operatingHours,
      space?.preferences?.operating
    )
  );

  useEffect(() => {
    healthCheck.latestIsInOperatingHours = isOperating;

    if (isOperating) {
      log.info({
        message: "Screen is in operating Hours",
        proofOfPlayFlag: true,
      });
    } else {
      log.info({
        message: "Screen is out of Operating Hours",
        proofOfPlayFlag: true,
      });
    }
  }, [isOperating]);

  const contentPathScreenId = useSelector<PlayerState, string | undefined>(
    (state) => state.config.contentConfig.id
  );
  // Get screen data via the contentPath sent from studio preview (screenById)
  // or via the js player on a paired device (getCurrentScreen)
  const [fetchScreen, { error, data }] = useManualQuery<
    ScreenByIdQuery | CurrentScreenQuery
  >(contentPathScreenId ? screenById : currentScreen, {
    useCache: false,
    skipCache: true,
    ...(contentPathScreenId ? { variables: { id: contentPathScreenId } } : {}),
  });

  const isInPairedDevice = useMemo(() => {
    return isLoadedInPairedDevice(
      config.contentConfig,
      config.device,
      config.graphqlToken
    );
  }, [config.contentConfig, config.graphqlToken, config.device]);

  // Fetch data on player load if it is a top level component (i.e isRoot)
  useInitialFetch(!!isRoot, fetchScreen);

  // Save screen data to state
  useEffect(() => {
    if (data) {
      const action = requestScreenSuccess(data);
      if (action !== undefined) {
        dispatch(action);
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data) {
      healthCheck.latestLastApiSync = Date.now();
      log.info("Request Screen data [SUCCESS]");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      log.error({
        message: "Request Screen data [FAIL]",
        context: {
          errorMessage: grqphqlHooksErrorMessage(error),
        },
      });
    }
  }, [error]);

  useEffect(() => {
    /**
     * need to init a remote logger here because need to wait to get the screen and org data from the backend
     * NOTE: a remote logger is not gonna work for standalone
     */

    // added check for both data && org?.id to ensuring that the required data, Org's featureflag
    // will be available during the initialize of RemoteLog
    // this help flush log afer initilize datadog passing the data to datadog correctly
    if (data && org?.id) {
      const screen =
        "currentScreen" in data
          ? data.currentScreen
          : (data as ScreenByIdQuery).screenById;
      // TODO need to disable a remote logging by checking other value instead of userInteractionEnabled
      if (
        !screen ||
        (contextConfig.userInteractionEnabled && !isEmbedPlayerMode())
      ) {
        return;
      }

      Logger.init(
        {
          clientToken: process.env.REACT_APP_DATADOG_TOKEN as string,
          version: getStudioPlayerVersion(),
          env: process.env.REACT_APP_SC_ENV as string,
          proxyHost:
            screen.orgByOrgId?.preferences?.player?.player_log_config?.endpoint,
          orgId: screen.orgByOrgId?.id,
          screenId: screen.id,
          screenName: screen.name,
          deviceId: screen.device.id,
          platform: screen.device.devicePlatform,
          timeOptions: timeOptions,
          graphqlToken: config.graphqlToken,
          proofOfPlayEndpoint: config.lokiEndpoint,
          spaceName: screen.spaceBySpaceId?.name,
          remoteLogEndPoint: process.env
            .REACT_APP_ENABLE_LOGS_ENDPOINT as string,
        },
        org.featureFlags // here is the featureflag update to taking control of logging enable / disable
      );
    }
  }, [
    data,
    contextConfig.userInteractionEnabled,
    timeOptions,
    org.featureFlags,
    config.graphqlToken,
    config.lokiEndpoint,
    org?.id,
  ]);

  // Report screen and device info data for studio
  const [
    updateSelfScreenDevice,
    { data: updateSelfScreenDeviceData, error: updateSelfScreenDeviceError },
  ] = useUpdateSelfScreenDevice();
  const [
    updateScreenDeviceInfo,
    { data: updateScreenDeviceInfoData, error: updateScreenDeviceInfoError },
  ] = useUpdateScreenDeviceInfo();

  const updateSelfScreenDeviceCallback = useCallback(
    async (updatedInput: UpdateSelfScreenDeviceInput) => {
      if (!isInPairedDevice) {
        return; // eg. Studio preview mode, we don't need to update any info.
      }

      updateSelfScreenDevice({
        variables: {
          input: updatedInput,
        },
      });
    },
    [updateSelfScreenDevice, isInPairedDevice]
  );
  const reportScreenDeviceInfo = useCallback(async () => {
    if (!isInPairedDevice) {
      return; // eg. Studio preview mode, we don't need to update any info.
    }
    const deviceInfo = await remoteApi.getDeviceInfoFromJSPlayer();

    updateScreenDeviceInfo({
      variables: {
        input: {
          deviceInfo,
        },
      },
    });
  }, [updateScreenDeviceInfo, isInPairedDevice]);

  // Further updates of screen device data when the player's screen changes size.
  const updateScreenResizeCallback = useCallback(() => {
    if (
      screen.playerHeight !== window.innerHeight ||
      screen.playerWidth !== window.innerWidth
    ) {
      const input = {
        playerHeight: window.innerHeight,
        playerWidth: window.innerWidth,
      };
      updateSelfScreenDeviceCallback(input);
    }
  }, [updateSelfScreenDeviceCallback, screen.playerHeight, screen.playerWidth]);

  useEffect(() => {
    if (updateSelfScreenDeviceData?.updateSelfScreenDevice?.screen) {
      dispatch(
        updateSelfScreenDeviceSuccess(
          updateSelfScreenDeviceData.updateSelfScreenDevice.screen
        )
      );
    }
  }, [updateSelfScreenDeviceData, dispatch]);
  useEffect(() => {
    if (updateScreenDeviceInfoData?.updateScreenDeviceInfo?.screen) {
      dispatch(
        updateScreenDeviceInfoSuccess(
          updateScreenDeviceInfoData?.updateScreenDeviceInfo?.screen
        )
      );
    }
  }, [updateScreenDeviceInfoData, dispatch]);

  useRetry(updateScreenDeviceInfoError !== undefined, reportScreenDeviceInfo);

  const initializeSelfScreenDeviceCallback = useCallback(async () => {
    if (!isInPairedDevice) {
      return; // eg. Studio preview mode, we don't need to update any info.
    }

    const deviceInfo = await remoteApi.getDeviceInfoFromJSPlayer();
    const input = {
      playerHeight: window.innerHeight,
      playerWidth: window.innerWidth,
      playerTimezone: getDeviceTimezoneName(),
      playerRelease: getStudioPlayerVersion(),
      deviceHostname: deviceInfo?.device?.hostname,
      deviceIpAddress: deviceInfo?.device?.ip,
    };
    updateSelfScreenDeviceCallback(input);
  }, [updateSelfScreenDeviceCallback, isInPairedDevice]);

  useRetry(
    updateSelfScreenDeviceError !== undefined,
    initializeSelfScreenDeviceCallback
  );

  // Only report to studio once we have the correct screenId
  useEffect(() => {
    if (screen.id !== "") {
      initializeSelfScreenDeviceCallback();
      reportScreenDeviceInfo();
    }
  }, [screen.id, initializeSelfScreenDeviceCallback, reportScreenDeviceInfo]);

  // Report studio size to studio
  useEffect(() => {
    const callback = debounce(updateScreenResizeCallback, 500);
    window.addEventListener("resize", callback);

    return (): void => {
      window.removeEventListener("resize", callback);
    };
  }, [updateScreenResizeCallback]);

  // Set timeout to check and update `isOperating` state according to current day's setting or at the end of
  //  current day
  useEffect(() => {
    const targetOperatingDayRules: OperatingDay | null = getScreenActiveOperatingRules(
      screenOperatingHours,
      spaceOperatingHours
    );

    let operatingHoursTimeout: number | null = null;

    const setOperatingTimeout = (): void => {
      if (targetOperatingDayRules === null) {
        // screen is always on and no need to update operating state
        return;
      }

      const nowDate = parseDateInLocalTime(
        timeOptions,
        playbackNowTimestamp(timeOptions)
      );
      const endOfDay = getEndOfDay(timeOptions);
      const startOfDay = getStartOfDay(timeOptions);

      const timeoutVariants: number[] = [endOfDay];

      // below we're checking if operating hours have end and start setting and then figuring out what is the next
      //  closest point in time to check if screen should be active

      const targetDayStart = targetOperatingDayRules?.[nowDate.weekDay]?.start;
      if (targetDayStart !== undefined) {
        const targetHoursStartTimestamp = startOfDay + targetDayStart * 1000;
        if (targetHoursStartTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursStartTimestamp);
        }
      }

      const targetDayEnd = targetOperatingDayRules?.[nowDate.weekDay]?.end;
      if (targetDayEnd !== undefined) {
        const targetHoursEndTimestamp = startOfDay + targetDayEnd * 1000;
        if (targetHoursEndTimestamp > nowDate.timestamp) {
          timeoutVariants.push(targetHoursEndTimestamp);
        }
      }

      const timeoutValue = Math.min(...timeoutVariants) - nowDate.timestamp;

      operatingHoursTimeout = window.setTimeout(() => {
        const newIsOperating = isInOperatingHours(
          timeOptions,
          undefined,
          screenOperatingHours,
          spaceOperatingHours
        );

        if (newIsOperating !== isOperating) {
          setIsOperating(newIsOperating);
        } else {
          setOperatingTimeout();
        }
      }, timeoutValue);
    };

    setOperatingTimeout();

    return (): void => {
      if (operatingHoursTimeout !== null) {
        window.clearTimeout(operatingHoursTimeout);
      }
    };
  }, [
    config,
    screenOperatingHours,
    spaceOperatingHours,
    isOperating,
    timeOptions,
  ]);

  useEffect(() => {
    const newIsOperating = isInOperatingHours(
      timeOptions,
      undefined,
      screenOperatingHours,
      spaceOperatingHours
    );
    if (newIsOperating !== isOperating) {
      setIsOperating(newIsOperating);
    }
  }, [timeOptions, screenOperatingHours, spaceOperatingHours, isOperating]);

  // the reason to use hook here directly instead of <LiveUpdateConnector /> is 2 options for an actual graphql query
  //  otherwise would be the same <LiveUpdateConnector/> component which uses screenById query by default
  useLiveUpdate(EntityType.SCREEN, screenId, fetchScreen);
  if (error && !screenId) {
    const errorCode = error.httpError?.status || "";
    const errorMessage = `Sorry, we can't load the screen. ${
      errorCode && `Error Code: ${errorCode}`
    }`;
    healthCheck.latestError = `${errorMessage} ${JSON.stringify(error)}`;
    return <p>{errorMessage}</p>;
  } else {
    healthCheck.latestError = "";
  }

  let view: ReactNode;
  if (!isOperating) {
    view = null;
  } else if (!screen.activeContentItem) {
    view = <Loading />;
  }
  // screen is deactivated
  else if (screenStatus === "PAUSED") {
    view = <Alert message={DEACTIVATE_SCREEN_MESSAGE} />;
  } else if (screenStatus === "SUSPENDED") {
    view = <Alert message={SUSPENDED_SCREEN_MESSAGE} />;
  } else if (isPreview) {
    // For Trial screen, we still need to show the content in the background so need to have GenericViewer before Alert component
    view = (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          <GenericViewer contentItem={screen.activeContentItem} />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Alert message={PREVIEW_SCREEN_MESSAGE} />
        </div>
      </>
    );
  } else {
    view = <GenericViewer contentItem={screen.activeContentItem} />;
  }
  return (
    <ScreenContext.Provider
      value={{
        timezoneOverride: screen.timezoneOverride ?? "",
      }}
    >
      {screen.spaceId ? (
        <>
          <LiveUpdateConnector
            entityId={screen.spaceId}
            entityType={EntityType.SPACE}
          />
          <ActiveContentContainer />
        </>
      ) : null}
      {view}
    </ScreenContext.Provider>
  );
};
