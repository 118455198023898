import {
  DocumentFileOutput,
  FileMediaType,
  PlayerFile,
} from "../store/files/types";
import { LeafContentType } from "../types/content";
import {
  DefaultDurations,
  Operating,
  OperatingDay,
} from "../store/graphqlTypes";
import {
  DEFAULT_APP_DURATION,
  DEFAULT_AUDIO_DURATION,
  DEFAULT_DOCUMENT_PAGE_DURATION,
  DEFAULT_IMAGE_DURATION,
  DEFAULT_LINK_DURATION,
  DEFAULT_VIDEO_DURATION,
} from "../constants";
import {
  ContentConfig,
  DeviceConfig,
  GraphqlToken,
} from "../store/config/types";
import {
  parseDateInLocalTime,
  TimeOptions,
  playbackNowTimestamp,
} from "./timeManager";
import { FileFragment, FileOutputFragment } from "../queries";
import crypto from "crypto";

// todo: this function duplicates code from signage-files-client package. When we start using that package,
//  duplication must be removed
export function fileMediaType(
  mimetype: string | undefined | null
): FileMediaType {
  if (!mimetype) {
    throw new Error("File's mimetype is not defined");
  }

  if (mimetype.startsWith("image")) {
    return "image";
  } else if (mimetype.startsWith("video")) {
    return "video";
  } else if (mimetype.startsWith("audio")) {
    return "audio";
  } else if (
    mimetype.startsWith("text") ||
    mimetype.startsWith("application")
  ) {
    return "document";
  } else {
    throw new Error("Can't detect file category.");
  }
}

export const getItemDefaultDuration = (
  itemType: LeafContentType,
  itemDefaultDurations: DefaultDurations | undefined,
  mimetype: string | undefined | null,
  documentPageAmount: number | undefined
): number => {
  switch (itemType) {
    case "app":
    case "editor":
      return itemDefaultDurations?.app || DEFAULT_APP_DURATION;
    case "link":
    case "site":
      return itemDefaultDurations?.link || DEFAULT_LINK_DURATION;
    case "file": {
      if (!mimetype) {
        throw new Error(
          "mimetype must be provided to get default duration of a file."
        );
      }
      const mediaType = fileMediaType(mimetype);

      switch (mediaType) {
        case "image":
          return itemDefaultDurations?.image || DEFAULT_IMAGE_DURATION;
        case "video":
          return DEFAULT_VIDEO_DURATION; // makes no sense, but this is to avoid unhandled exceptions
        case "audio":
          return DEFAULT_AUDIO_DURATION; // makes no sense, but this is to avoid unhandled exceptions
        case "document":
          return (
            (itemDefaultDurations?.document || DEFAULT_DOCUMENT_PAGE_DURATION) *
            (documentPageAmount || 1)
          );
      }
    }
  }
};

/**
 * Returns declared duration or default duration for an item
 */
export function getItemFullDuration(
  declaredDuration: number | undefined,
  contentType: LeafContentType,
  defaultDurations: DefaultDurations | undefined,
  mimetype: string | undefined | null,
  documentPageAmount: number | undefined
): number {
  if (declaredDuration) {
    return declaredDuration;
  } else {
    return getItemDefaultDuration(
      contentType,
      defaultDurations,
      mimetype,
      documentPageAmount
    );
  }
}

/**
 * Returns a content config object depending on the supplied content path or graphql token
 */
export const getContentConfig = (
  contentPath: string | undefined,
  graphqlToken: string | undefined
): ContentConfig => {
  if (contentPath) {
    const splitContentPath = contentPath.substring(1).split("/");
    const [type, ...ids] = splitContentPath;
    switch (type) {
      case "appId":
        // app path contains appId/{applicationId}/{instanceId}
        if (!(ids.length === 2 || ids.length === 3)) {
          throw Error("Content path is not valid");
        }
        if (ids.length === 3 && ids[2] === "preview") {
          return { type: "app", appId: ids[0], id: ids[1], isPreview: true };
        }
        if (ids.length === 3 && ids[2] === "editor") {
          return {
            type: "editor",
            appId: ids[0],
            id: ids[1],
            isPreview: false,
          };
        }
        return { type: "app", appId: ids[0], id: ids[1], isPreview: false };
      case "site":
        return { type: "site", id: ids[0], isPreview: true };
      case "playlist":
      case "channel":
        if (ids.length !== 1) {
          throw Error("Content path is not valid");
        }
        return {
          type,
          id: ids[0],
          isPreview: false,
        };
      case "screen":
        if (ids.length > 1) {
          throw Error("Content path is not valid");
        }
        return {
          type: "screen",
          id: ids[0],
          isPreview: false,
        };

      default:
        throw Error("Content type is not valid");
    }
  } else if (graphqlToken) {
    // when there is no content path and only graphql token - it means this is a paired screen on a device
    const screenId = getScreenIdFromGraphqlToken(graphqlToken);

    if (!screenId) {
      throw new Error("Screen id is missing in graphql token.");
    }

    return {
      type: "screen",
      isPreview: false,
      id: screenId,
    };
  } else {
    throw new Error("ContentPath and graphqlToken can not both be undefined.");
  }
};

export const getScreenIdFromGraphqlToken = (
  token: string
): string | undefined => {
  const parsedToken = parseJwt<GraphqlToken>(token);
  return parsedToken.screen_id;
};

export const getInfoFromGraphqlToken = (
  token: string
): { screenId: string; orgId: string; role: string } => {
  const parsedToken = parseJwt<GraphqlToken>(token);
  return {
    screenId: parsedToken.screen_id ?? "",
    orgId: parsedToken.org_id ?? "",
    role: parsedToken.role ?? "",
  };
};

export const checkForGraphQLTokenIssues = (
  prefix: string,
  token: string
): string | undefined => {
  if (!token || token === "") {
    return `${prefix}: Empty GraphQL token received`;
  } else if (token.match(/\./g)?.length !== 2) {
    return `${prefix}: Incorrect GraphQL token format received`;
  } else {
    try {
      const timestamps = getTimestampsFromGraphqlToken(token);
      const { role } = getInfoFromGraphqlToken(token);
      if (parseInt(timestamps.exp) * 1000 <= Date.now()) {
        return `${prefix}: Expired GraphQL token received - exp: ${timestamps.exp} iat: ${timestamps.iat} role: ${role}`;
      }
    } catch (err) {
      return `${prefix}: Unable to parse GraphQL token`;
    }
  }
  return undefined;
};

export const getTimestampsFromGraphqlToken = (
  token: string
): { iat: string; exp: string } => {
  const parsedToken = parseJwt<GraphqlToken>(token);
  return {
    iat: parsedToken.iat ?? "",
    exp: parsedToken.exp ?? "",
  };
};

/**
 * Detects if player was loaded as a paired screen (vs preview with a content path)
 */
export const isLoadedInPairedDevice = (
  contentConfig: ContentConfig,
  deviceConfig: DeviceConfig | undefined,
  graphqlToken: string
): boolean => {
  const parsedToken = parseJwt<GraphqlToken>(graphqlToken);
  return (
    parsedToken.role === "STUDIO_SCREEN" &&
    !!parsedToken.screen_id &&
    contentConfig.type === "screen" &&
    deviceConfig?.model !== "screen-preview"
  );
};

export const getDocumentFileOutput = (
  fileOutputsByFileId: FileOutputFragment[]
): DocumentFileOutput | undefined => {
  if (fileOutputsByFileId.length > 0 && fileOutputsByFileId[0].mimetype) {
    return {
      urlKeys: fileOutputsByFileId[0].content.keys,
      mimetype: fileOutputsByFileId[0].mimetype,
    };
  }
  return undefined;
};

/**
 * Returns a set of image urls that represent document pages
 */
export const getDocumentPages = (file: FileFragment | PlayerFile): string[] => {
  if (isGqlFile(file)) {
    const fileOutput = getDocumentFileOutput(file.fileOutputsByFileId.nodes);
    return fileOutput?.urlKeys || [];
  } else if (file.type === "document") {
    return file.images.map((image) => image.urlKey);
  } else {
    return [];
  }
};

export const isGqlFile = (
  entity: FileFragment | PlayerFile | undefined
): entity is FileFragment => {
  return entity?.hasOwnProperty("fileOutputsByFileId") || false;
};

/**
 * Figures out if the screen is operating at the targetTimestamp.
 */
export const isInOperatingHours = (
  timeOptions: TimeOptions,
  targetTimestamp: number | undefined,
  screenOperating: Operating | undefined,
  spaceOperating: Operating | undefined
): boolean => {
  const now = targetTimestamp ?? playbackNowTimestamp(timeOptions);
  const nowDate = parseDateInLocalTime(timeOptions, now);

  const applyRules = (targetOperatingHours: OperatingDay): boolean => {
    const targetRule = targetOperatingHours[nowDate.weekDay];
    if (targetRule && !targetRule.enable) {
      return false;
    }

    const targetStartSecond = targetOperatingHours[nowDate.weekDay]?.start;
    const targetEndSecond = targetOperatingHours[nowDate.weekDay]?.end;

    return (
      (targetStartSecond === undefined ||
        targetStartSecond <= nowDate.secondFromDayStart) &&
      (targetEndSecond === undefined ||
        targetEndSecond > nowDate.secondFromDayStart)
    );
  };

  const activeOperatingRules = getScreenActiveOperatingRules(
    screenOperating,
    spaceOperating
  );

  if (activeOperatingRules === null) {
    return true;
  } else {
    return applyRules(activeOperatingRules);
  }
};

/**
 * Get a set of week days operating rules to be applied for a screen.
 * If null is returned - screen is always on
 */
export const getScreenActiveOperatingRules = (
  screenOperating: Operating | undefined,
  spaceOperating: Operating | undefined
): OperatingDay | null => {
  if (screenOperating && screenOperating.alwaysOn) {
    return null;
  } else if (
    screenOperating &&
    !screenOperating.alwaysOn &&
    screenOperating.enable &&
    screenOperating.day
  ) {
    return screenOperating.day;
  } else if (spaceOperating && spaceOperating.alwaysOn) {
    return null;
  } else if (
    spaceOperating &&
    !spaceOperating.alwaysOn &&
    spaceOperating.enable &&
    spaceOperating.day
  ) {
    return spaceOperating.day;
  } else {
    return null;
  }
};

export const insertRegion = (line: string, region: string): string => {
  return line.replace(/{region}/g, region);
};

export const parseJwt = <T extends Record<string, string | undefined>>(
  token: string
): T | Record<string, never> => {
  const base64Url = token.split(".")[1];

  if (!base64Url) {
    // ignore incorrect token input
    return {};
  }

  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
};

/**
 * Produce a sha1 hash of a given string input.
 */
export const stringHash = (inputString: string): string => {
  return crypto.createHash("sha1").update(inputString).digest("hex");
};
