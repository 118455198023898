import React, { FunctionComponent, memo, useCallback } from "react";
import { useFullscreenContent } from "./hooks/useFullscreenContent";
import { ContentFailureCallback, RenderSlot } from "../../TimelineViewer/types";
import {
  getActiveItemRenderSlotIndex,
  getPreloadItemRenderSlotIndex,
} from "./utils";
import styles from "./FullscreenContentViewer.module.css";
import classnames from "classnames";
import { TimelineSlot } from "../../TimelineViewer/TimelineSlot/TimelineSlot";
import { SlotContent } from "../../TimelineViewer/TimelineSlot/SlotContent";
import { FullscreenZoneTransitioningItemInfo } from "./types";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../../store/rootReducer";
import { TimelinesPlaybackState } from "../../../../../store/playback/types";
import { useFullscreenZoneTransitioningItemInfo } from "./hooks/useFullscreenZoneTransitioningItemInfo";
import { useChannelLayoutZoneTransitioningItemInfo } from "./hooks/useChannelLayoutZoneTransitioningItemInfo";
import { useFullscreenRenderSlots } from "./hooks/useFullscreenRenderSlots";
import { useIsActiveFullscreenSlotTransitioningOut } from "./hooks/useIsActiveFullscreenSlotTransitioningOut";
import { useTransitionDefinitions } from "./hooks/useTransitionDefinitions";
import { useActiveFullscreenItemTransitioningOut } from "./hooks/useActiveFullscreenItemTransitioningOut";

/**
 * Fullscreen transitions implementation overview.
 *
 * The key idea of fullscreen item rendering: render a separate dom node - a "fullscreen zone", which is styled to
 * always render on top of the channel's layout. This effectively allows maintaining existing preloading mechanisms
 * and pre-rendering of content.
 */

export const FullscreenContentViewerContainer: FunctionComponent = (props) => {
  const timelinesPlaybackState = useSelector<
    PlayerState,
    TimelinesPlaybackState
  >((state) => state.playback.timelines);
  const fullscreenContent = useFullscreenContent();
  const fullscreenRenderSlots = useFullscreenRenderSlots(fullscreenContent);

  const channelLayoutZoneTransitionItemInfo = useChannelLayoutZoneTransitioningItemInfo(
    fullscreenContent
  );
  const fullscreenZoneTransitionItemInfo = useFullscreenZoneTransitioningItemInfo(
    fullscreenContent
  );

  const isActiveFullscreenZoneTransitioningOut = useIsActiveFullscreenSlotTransitioningOut(
    fullscreenContent,
    timelinesPlaybackState
  );

  const isActiveFullscreenItemTransitioningOut = useActiveFullscreenItemTransitioningOut(
    fullscreenContent,
    timelinesPlaybackState
  );

  const isFullscreenActive = !!fullscreenContent.active?.item;

  return (
    <FullscreenContentViewer
      renderSlots={fullscreenRenderSlots}
      channelLayoutZoneTransitionItemInfo={channelLayoutZoneTransitionItemInfo}
      fullscreenZoneTransitionItemInfo={fullscreenZoneTransitionItemInfo}
      isActiveFullscreenZoneTransitioningOut={
        isActiveFullscreenZoneTransitioningOut
      }
      isActiveFullscreenItemTransitioningOut={
        isActiveFullscreenItemTransitioningOut
      }
      isFullscreenActive={isFullscreenActive}
    >
      {props.children}
    </FullscreenContentViewer>
  );
};

interface FullscreenContentViewerProps {
  renderSlots: RenderSlot[];
  onContentFailureCb?: ContentFailureCallback;
  channelLayoutZoneTransitionItemInfo: FullscreenZoneTransitioningItemInfo;
  fullscreenZoneTransitionItemInfo: FullscreenZoneTransitioningItemInfo;
  isActiveFullscreenZoneTransitioningOut: boolean; // the whole fullscreen zone is transitioning out
  isActiveFullscreenItemTransitioningOut: boolean; // a piece of content inside a fullscreen zone is transitioning out
  isFullscreenActive: boolean;
}

export const FullscreenContentViewer: FunctionComponent<FullscreenContentViewerProps> = memo(
  (props) => {
    const {
      renderSlots,
      onContentFailureCb,
      children,
      channelLayoutZoneTransitionItemInfo,
      fullscreenZoneTransitionItemInfo,
      isActiveFullscreenZoneTransitioningOut,
      isActiveFullscreenItemTransitioningOut,
      isFullscreenActive,
    } = props;

    const onContentFailure = useCallback(
      (itemIndex: number) => {
        if (onContentFailureCb) {
          onContentFailureCb(itemIndex);
        }
      },
      [onContentFailureCb]
    );

    const activeRenderSlotIndex = getActiveItemRenderSlotIndex(renderSlots);
    const activeRenderSlot = renderSlots[activeRenderSlotIndex];

    const preloadRenderSlotIndex = getPreloadItemRenderSlotIndex(renderSlots);
    const preloadRenderSlot = renderSlots[preloadRenderSlotIndex];

    const transitionDefinitions = useTransitionDefinitions(
      activeRenderSlot,
      preloadRenderSlot
    );

    return (
      <>
        {/**
         Transition mechanism of switching channel layout to fullscreen and back mimics transitions between items in
         the TimelineViewer. Thus, the same react components are used with identical props set.
        */}
        <TimelineSlot
          index={22}
          transition={transitionDefinitions.channelLayoutZone}
          isActiveItemTransitioningOut={isActiveFullscreenZoneTransitioningOut}
          targetTimelineId={
            channelLayoutZoneTransitionItemInfo.targetTimelineId
          }
          indexInTimeline={
            channelLayoutZoneTransitionItemInfo.targetIndexInTimeline
          }
          forceVisible
        >
          {children}
        </TimelineSlot>
        <TimelineSlot
          index={53}
          transition={transitionDefinitions.fullscreenZone}
          isActiveItemTransitioningOut={isActiveFullscreenZoneTransitioningOut}
          forceBackground={
            !isActiveFullscreenZoneTransitioningOut && isFullscreenActive
          }
          targetTimelineId={fullscreenZoneTransitionItemInfo.targetTimelineId}
          indexInTimeline={
            fullscreenZoneTransitionItemInfo.targetIndexInTimeline
          }
        >
          <div className={classnames(styles.container)}>
            {/**
             Render slots within the fullscreen zone mimic the timeline playback mechanism and uses same react
             components and identical props as inside the TimelineViewer itself
            */}
            {renderSlots.map((slot, idx) =>
              slot.item && typeof slot.indexInTimeline === "number" ? (
                <TimelineSlot
                  index={idx}
                  key={idx}
                  isActiveItemTransitioningOut={
                    isActiveFullscreenItemTransitioningOut
                  }
                  transition={slot.item.transition}
                  targetTimelineId={slot.timelineId}
                  indexInTimeline={slot.indexInTimeline}
                >
                  <SlotContent
                    item={slot.item}
                    isPreload={slot.isPreload}
                    onContentFailure={onContentFailure}
                    timelineItemIndex={slot.indexInTimeline}
                  />
                </TimelineSlot>
              ) : (
                <TimelineSlot
                  index={idx}
                  key={idx}
                  transition={undefined}
                  isActiveItemTransitioningOut={
                    isActiveFullscreenItemTransitioningOut
                  }
                  targetTimelineId={slot.timelineId}
                  indexInTimeline={slot.indexInTimeline}
                />
              )
            )}
          </div>
        </TimelineSlot>
      </>
    );
  }
);
