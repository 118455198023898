"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageManager = void 0;
var utils_1 = require("./utils");
var toMB = function (bytes) { return +(utils_1.bytesToMB(bytes)).toFixed(2); };
var toPercentage = function (part, total) { return +(part / total * 100).toFixed(2); };
var StorageManager = /** @class */ (function () {
    function StorageManager() {
    }
    StorageManager.prototype.storage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var getEstimate, estimate, quota, usage, usageDetails, usageDetailsSummary, caches_1, indexedDB_1, serviceWorkerRegistrations;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        getEstimate = function () { return __awaiter(_this, void 0, void 0, function () {
                            var isAvailable, estimate;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        isAvailable = ('storage' in navigator && 'estimate' in navigator.storage);
                                        if (!isAvailable) {
                                            return [2 /*return*/, null];
                                        }
                                        return [4 /*yield*/, navigator.storage.estimate()];
                                    case 1:
                                        estimate = _a.sent();
                                        return [2 /*return*/, estimate];
                                }
                            });
                        }); };
                        return [4 /*yield*/, getEstimate()];
                    case 1:
                        estimate = _a.sent();
                        if (!estimate)
                            return [2 /*return*/, null];
                        quota = estimate.quota, usage = estimate.usage, usageDetails = estimate.usageDetails;
                        if (usageDetails) {
                            caches_1 = usageDetails.caches, indexedDB_1 = usageDetails.indexedDB, serviceWorkerRegistrations = usageDetails.serviceWorkerRegistrations;
                            usageDetailsSummary = {
                                caches: caches_1 || 0,
                                indexedDB: indexedDB_1 || 0,
                                serviceWorkerRegistrations: serviceWorkerRegistrations || 0,
                            };
                        }
                        return [2 /*return*/, {
                                quota: quota,
                                usage: usage,
                                remaining: quota - usage,
                                usageDetails: usageDetailsSummary
                            }];
                }
            });
        });
    };
    // human readable summary
    StorageManager.prototype.storageSummary = function () {
        return __awaiter(this, void 0, void 0, function () {
            var storage, quota, usage, remaining, usageDetails, usageDetailsSummary, caches_2, indexedDB_2, serviceWorkerRegistrations;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage()];
                    case 1:
                        storage = _a.sent();
                        if (!storage)
                            return [2 /*return*/, null];
                        quota = storage.quota, usage = storage.usage, remaining = storage.remaining, usageDetails = storage.usageDetails;
                        if (usageDetails) {
                            caches_2 = usageDetails.caches, indexedDB_2 = usageDetails.indexedDB, serviceWorkerRegistrations = usageDetails.serviceWorkerRegistrations;
                            usageDetailsSummary = {
                                caches: toMB(caches_2),
                                cachesPercentage: toPercentage(caches_2, usage),
                                indexedDB: toMB(indexedDB_2),
                                indexedDBPercentage: toPercentage(indexedDB_2, usage),
                                serviceWorkerRegistrations: toMB(serviceWorkerRegistrations),
                                serviceWorkerRegistrationsPercentage: toPercentage(serviceWorkerRegistrations, usage)
                            };
                        }
                        return [2 /*return*/, {
                                quota: toMB(quota),
                                usage: toMB(usage),
                                usagePercentage: toPercentage(usage, quota),
                                remaining: toMB(remaining),
                                remainingPercentage: toPercentage(remaining, quota),
                                usageDetails: usageDetailsSummary
                            }];
                }
            });
        });
    };
    return StorageManager;
}());
exports.StorageManager = StorageManager;
