import { ContentItemLeaf, ContentList } from "../../store/contentLists/types";
import { BreakpointWithItems } from "../../store/playback/types";
import {
  TimelineContinuousStartItem,
  TimelineItem,
  TimelineItemContent,
  TimelineItemsForSegment,
  TimelineItemsWithOptionsInputs,
  TimelineItemVoid,
} from "../../store/timelines/types";
import { isTimelineItemVoid } from "../../utils/contentItemTypeGuards";
import { getTotalLoopItems, joinTimeline, produceTimelineItem } from "./utils";
import { Logger } from "../../logger/logger";

const log = new Logger("localGenerator");

/** To generate timeline items from loop items with options */
/** segment is never return over loop content */
const getTimelineSegmentItemsWithOptions = (
  inputs: TimelineItemsWithOptionsInputs
): TimelineItemsForSegment => {
  const resultTimelineItems: TimelineItem[] = [];
  const { startTimeOffsetMs, maxTargetDurationMs, timelineLoopItems } = inputs;
  const totalItems = timelineLoopItems.length;
  let playTimeTrackingMs = inputs.playTimeTrackingMs;
  let sumDurationMs = 0;

  // 0 nothing to show
  if (totalItems === 0 || maxTargetDurationMs <= 0)
    return { items: [], durationMs: 0, playTimeTrackingMs };

  // 1 item
  if (totalItems === 1) {
    const timelineItem: TimelineItemContent = Object.assign(
      {},
      timelineLoopItems[0]
    ) as TimelineItemContent;

    if (startTimeOffsetMs >= timelineItem.fullDurationMs) {
      return { items: [], durationMs: 0, playTimeTrackingMs };
    } else {
      timelineItem.startTimestampMs = playTimeTrackingMs;
      timelineItem.showDurationMs =
        timelineItem.fullDurationMs - startTimeOffsetMs;
      playTimeTrackingMs += timelineItem.showDurationMs;
      return {
        items: [timelineItem],
        durationMs: timelineItem.showDurationMs,
        playTimeTrackingMs: playTimeTrackingMs,
      };
    }
  }

  // > 1 items
  // Find start idex from the startTimeOffset
  for (let i = 0; i < totalItems; i++) {
    const firstFoundTimelineItem: TimelineItemContent = Object.assign(
      {},
      timelineLoopItems[i]
    ) as TimelineItemContent;

    // agregate sum of items' duration
    sumDurationMs += firstFoundTimelineItem.fullDurationMs;

    // find the first item by check is start time offset is inside the agregate sum duration
    if (startTimeOffsetMs < sumDurationMs) {
      // update first found timeline item
      firstFoundTimelineItem.startTimestampMs = playTimeTrackingMs;
      firstFoundTimelineItem.showDurationMs = sumDurationMs - startTimeOffsetMs;
      firstFoundTimelineItem.showDurationMs =
        firstFoundTimelineItem.showDurationMs <= maxTargetDurationMs
          ? firstFoundTimelineItem.showDurationMs
          : maxTargetDurationMs;
      playTimeTrackingMs += firstFoundTimelineItem.showDurationMs;
      resultTimelineItems.push(firstFoundTimelineItem);

      // reset sumDuration to the be the actual play time of the first found item
      sumDurationMs = firstFoundTimelineItem.showDurationMs;

      // continue generate timeline item from next item after the found item idex
      for (let m = i + 1; m < totalItems; m++) {
        // exit after generate enough duration to the limitation in maxTargetDurationMs
        if (sumDurationMs >= maxTargetDurationMs) break;

        const timelineItem: TimelineItemContent = Object.assign(
          {},
          timelineLoopItems[m]
        ) as TimelineItemContent;
        timelineItem.startTimestampMs = playTimeTrackingMs;
        // calculate the actual show duration againt the limit by maxTargetDurationMs
        const sumDurationsWithPlayFullDurationMs =
          sumDurationMs + timelineItem.fullDurationMs;
        if (sumDurationsWithPlayFullDurationMs <= maxTargetDurationMs) {
          timelineItem.showDurationMs = timelineItem.fullDurationMs;
        } else {
          timelineItem.showDurationMs =
            timelineItem.fullDurationMs -
            (sumDurationsWithPlayFullDurationMs - maxTargetDurationMs);
          // leave the log comment below for debug
          // console.warn(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! FOUND ITEM PLAY NOT FULL = ${timelineItem.listId} : ${timelineItem.showDurationMs}/${timelineItem.fullDurationMs}`);
        }
        playTimeTrackingMs += timelineItem.showDurationMs;
        resultTimelineItems.push(timelineItem);
        sumDurationMs += timelineItem.showDurationMs;
      }
      // break of from firstFoundTimelineItem
      break;
    }
  }

  return {
    items: resultTimelineItems,
    durationMs: sumDurationMs,
    playTimeTrackingMs,
  };
};

/** Return the start playback item index with offset from the recent played item */
const getContinuePlayForItem = (
  currentBpWithItems: BreakpointWithItems,
  recentItem: TimelineItemContent
): TimelineContinuousStartItem => {
  const currentBpTotalItemsCount = currentBpWithItems.validItemsCount;
  // default to first item with 0 and 0 offset = no start offset play required
  let startItemIdx = -1;
  let startTimeOffsetMs = 0;

  for (let idx = 0; idx < currentBpTotalItemsCount; idx++) {
    // search for recent played item
    if (
      currentBpWithItems.items[idx].id &&
      currentBpWithItems.items[idx].id === recentItem.id
    ) {
      if (recentItem.showDurationMs < recentItem.fullDurationMs) {
        // it has been play some part of the duration
        startItemIdx = idx;
        startTimeOffsetMs += recentItem.showDurationMs;
      } else {
        // it has play full duration for the last play item
        // so find the proper next item idx to continue play
        if (idx < currentBpTotalItemsCount - 1) {
          // include the found item's duration to the offset to start show with next item
          startTimeOffsetMs += currentBpWithItems.items[idx].durationMs;
          startItemIdx = idx + 1;
        } else {
          // next item is the first item so reset everything to 0
          startItemIdx = 0;
          startTimeOffsetMs = 0;
        }
      }
      break;
    }
    // not found case, keep looking forward
    startTimeOffsetMs += currentBpWithItems.items[idx].durationMs;
  }

  // if not found item then reset all to 0
  if (startItemIdx === -1) {
    startTimeOffsetMs = 0;
    startItemIdx = 0;
  }

  return { startItemIdx, startTimeOffsetMs };
};

/** Convert the list of contentItem leaf to timelineItem */
const contentListToTimelineItems = (
  bpWithItems: BreakpointWithItems
): TimelineItem[] => {
  const items: ContentItemLeaf[] = bpWithItems.items;
  const totalItems = items.length;
  const timelineItems: TimelineItem[] = new Array(totalItems);
  let lastItem: TimelineItemContent | undefined;

  for (let k = 0; k < totalItems; k++) {
    const item = items[k];
    // sanitize items by merge adjacent duplicate items
    if (lastItem && lastItem.id === item.id) {
      lastItem.fullDurationMs += item.durationMs;
    } else {
      const startTimestampMs = -1; // default to -1 and will be calculate and update later
      const breakpointId = bpWithItems.id;
      const isInfinite = false;
      const showDurationMs = -1, // default to -1 and will be calculate and update later
        timelineItem = produceTimelineItem(
          item,
          startTimestampMs,
          isInfinite,
          showDurationMs,
          breakpointId
        );

      timelineItems[k] = timelineItem;
      lastItem = timelineItem;
    }
  }
  return timelineItems;
};

export const getBreakpointTimeline = (
  breakpointsWithItems: BreakpointWithItems[],
  contentList: ContentList
) => {
  let finalBpTimelines: TimelineItem[] = [];
  let lastItemFromPreviousBp: TimelineItem | undefined = undefined;
  let playTimeTrackingMs = 0; // This is for tracking of play timing and turn into startTimestamp for each item
  const totalBreakpoints: number = breakpointsWithItems.length;

  for (let i = 0; i < totalBreakpoints - 1; i++) {
    let bpTimelines: TimelineItem[] = [];
    const bpWithItems = breakpointsWithItems[i];
    const nextBp = breakpointsWithItems[i + 1];
    const bpFullDuration =
      nextBp.breakpointTimestamp - bpWithItems.breakpointTimestamp;
    const validItemsCount = bpWithItems.validItemsCount;
    const validItemsTotalDurationMs = bpWithItems.totalDurationMs;
    const fullLoopTimelineItems: TimelineItem[] = contentListToTimelineItems(
      bpWithItems
    );
    let isFullLoopTimelineItemsRequireMerge = false;
    // to check is the loop items contain duplicate item for the first and last item.
    if (fullLoopTimelineItems.length >= 2) {
      const firstLoopItem = fullLoopTimelineItems[0];
      const lastLoopItem =
        fullLoopTimelineItems[fullLoopTimelineItems.length - 1];
      if (firstLoopItem.type === "void" && lastLoopItem.type === "void") {
        isFullLoopTimelineItemsRequireMerge = true;
      } else if (
        !isTimelineItemVoid(firstLoopItem) &&
        !isTimelineItemVoid(lastLoopItem) &&
        firstLoopItem.id === lastLoopItem.id
      ) {
        isFullLoopTimelineItemsRequireMerge = true;
      }
    }

    playTimeTrackingMs =
      playTimeTrackingMs === 0
        ? bpWithItems.breakpointTimestamp
        : playTimeTrackingMs;

    if (validItemsTotalDurationMs > 0 && validItemsCount > 0) {
      if (validItemsCount === 1) {
        // = 1 item in the breakpoint then set it's duration to the breakpoint fullDuration
        const timelineItem = Object.assign(
          {},
          fullLoopTimelineItems[0] // use modulo to progress through index items
        );
        timelineItem.startTimestampMs = playTimeTrackingMs;
        timelineItem.showDurationMs = bpFullDuration;
        playTimeTrackingMs += bpFullDuration;
        bpTimelines = [timelineItem];
        lastItemFromPreviousBp = timelineItem;
      } else {
        // > 1 items
        // -------------------- "start Segment" --------------------------
        // default to start at first item with 0 offset
        let startBpItem: TimelineContinuousStartItem = {
          startItemIdx: 0,
          startTimeOffsetMs: 0,
        };

        // [continuous playback], find the last play item from previous break point to continue
        // apply continuous playback only non void content
        if (
          lastItemFromPreviousBp &&
          !isTimelineItemVoid(lastItemFromPreviousBp)
        ) {
          startBpItem = getContinuePlayForItem(
            bpWithItems,
            lastItemFromPreviousBp
          );
        }

        // if start item is idx=0 with offset=0 then no start segment required, it will include in the loop
        const startSegmentDuration =
          startBpItem.startItemIdx === 0 && startBpItem.startTimeOffsetMs === 0
            ? 0
            : validItemsTotalDurationMs - startBpItem.startTimeOffsetMs;

        const startSegment = getTimelineSegmentItemsWithOptions({
          timelineLoopItems: fullLoopTimelineItems,
          startTimeOffsetMs: startBpItem.startTimeOffsetMs,
          playTimeTrackingMs: playTimeTrackingMs,
          maxTargetDurationMs: startSegmentDuration,
        });
        playTimeTrackingMs = startSegment.playTimeTrackingMs;

        // -------------------- "Full Loop Segment" --------------------------
        const bpFulldurationAfterStartSegment =
          bpFullDuration - startSegment.durationMs;
        const maxFullLoopRepeat =
          bpFulldurationAfterStartSegment > 0
            ? Math.floor(
                bpFulldurationAfterStartSegment / validItemsTotalDurationMs
              )
            : 0;

        // the full loop that have start and end with the same content, require merge
        // equation to get the generated loop item count with merge tail+head during join
        // so the equation is (loopItemsCount * n) - (n - 1)
        const totalLoopItemsGen = maxFullLoopRepeat * validItemsCount;
        const totalLoopItems = getTotalLoopItems(
          isFullLoopTimelineItemsRequireMerge,
          bpFullDuration,
          startSegment.durationMs,
          validItemsTotalDurationMs,
          validItemsCount
        );
        const loopSegmentTimelineItems: TimelineItem[] = new Array(
          totalLoopItems
        );

        let loopCount = 0;
        let lastItem: TimelineItem | undefined;

        if (window.isNaN(totalLoopItemsGen) || totalLoopItemsGen === Infinity) {
          // additional infinite loop protection
          throw new Error(
            `totalLoopItemsGen is not a number. Value: ${totalLoopItemsGen}`
          );
        }

        while (loopCount < totalLoopItemsGen) {
          const itemIdx = loopCount % validItemsCount;
          const timelineItem = Object.assign(
            {},
            fullLoopTimelineItems[itemIdx] // use modulo to progress through index items
          );

          // sanitize items by merge adjacent duplicate items
          if (
            isFullLoopTimelineItemsRequireMerge &&
            itemIdx === 0 &&
            lastItem !== undefined
          ) {
            // extend last item instead of adding new one
            lastItem.fullDurationMs += timelineItem.fullDurationMs;
            lastItem.showDurationMs += timelineItem.fullDurationMs;
          } else {
            timelineItem.startTimestampMs = playTimeTrackingMs;
            timelineItem.showDurationMs = timelineItem.fullDurationMs;
            loopSegmentTimelineItems[loopCount] = timelineItem;
          }
          playTimeTrackingMs += timelineItem.showDurationMs;
          loopCount++;
          lastItem = timelineItem;
        }

        // -------------------- "endSegment" --------------------------
        const endSegmentDuration =
          bpFulldurationAfterStartSegment > 0
            ? bpFulldurationAfterStartSegment % validItemsTotalDurationMs
            : 0;

        const endSegment = getTimelineSegmentItemsWithOptions({
          timelineLoopItems: fullLoopTimelineItems,
          startTimeOffsetMs: 0, // end segment always start from 0
          playTimeTrackingMs: playTimeTrackingMs,
          maxTargetDurationMs: endSegmentDuration,
        });

        playTimeTrackingMs = endSegment.playTimeTrackingMs;

        // make sure there is no duplicate item between last segement and previous segment:
        const lastItemOfPreviousSegment =
          loopSegmentTimelineItems.length > 0
            ? loopSegmentTimelineItems[loopSegmentTimelineItems.length - 1]
            : startSegment.items.length > 0
            ? startSegment.items[startSegment.items.length - 1]
            : undefined;
        const firstItemOfEndSegment =
          endSegment.items.length > 0 ? endSegment.items[0] : undefined;

        if (
          lastItemOfPreviousSegment &&
          firstItemOfEndSegment &&
          ((lastItemOfPreviousSegment.type === "void" &&
            firstItemOfEndSegment.type === "void") ||
            (firstItemOfEndSegment.type !== "void" &&
              lastItemOfPreviousSegment.type !== "void" &&
              firstItemOfEndSegment.id === lastItemOfPreviousSegment.id))
        ) {
          lastItemOfPreviousSegment.fullDurationMs +=
            firstItemOfEndSegment.fullDurationMs;
          lastItemOfPreviousSegment.showDurationMs +=
            firstItemOfEndSegment.showDurationMs;
          endSegment.items.splice(0, 1);
        }

        const totalBpItemsDuration =
          startSegmentDuration +
          maxFullLoopRepeat * validItemsTotalDurationMs +
          endSegmentDuration;

        if (totalBpItemsDuration !== bpFullDuration) {
          log.warn({
            message:
              "!!!!!!!!!!!!!! breakpoint full duration vs fill items duration are mismatch !!!!!!!!!!!!",
            context: {
              contentListId: contentList.id,
            },
            proofOfPlayFlag: true,
          });
        }

        // optimization for performance
        bpTimelines.push(
          ...startSegment.items,
          ...loopSegmentTimelineItems,
          ...endSegment.items
        );
        lastItemFromPreviousBp = bpTimelines[bpTimelines.length];
      }
    } else {
      // Void item here
      const blankTimelineItem: TimelineItemVoid = {
        breakpointId: i,
        fullDurationMs: bpFullDuration,
        isInfinite: false,
        showDurationMs: bpFullDuration,
        startTimestampMs: playTimeTrackingMs,
        type: "void",
      };
      playTimeTrackingMs += bpFullDuration;
      bpTimelines = [blankTimelineItem];
      lastItemFromPreviousBp = blankTimelineItem;
    }

    // 4. join each breakpoint final timelines
    // Check and merge the last item from previous breakpoints and the first one for
    // the current breakpoint. Merge if it is the same one.
    finalBpTimelines = joinTimeline(finalBpTimelines, bpTimelines);
  }

  finalBpTimelines = finalBpTimelines.filter((item) => !!item);
  return finalBpTimelines;
};
