import { useSelector } from "react-redux";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import difference from "lodash/difference";
import { ChannelsState } from "../store/channels/types";
import { OrganizationState } from "../store/organization/types";
import { PlayerState } from "../store/rootReducer";
import { ScreenState } from "../store/screen/types";

export enum STUDIO_VARIABLES {
  $_SC_STUDIO_ORG_ID = "$_SC_STUDIO_ORG_ID",
  $_SC_STUDIO_SPACE_ID = "$_SC_STUDIO_SPACE_ID",
  $_SC_STUDIO_SCREEN_ID = "$_SC_STUDIO_SCREEN_ID",
  $_SC_STUDIO_CHANNEL_ID = "$_SC_STUDIO_CHANNEL_ID",
  $_SC_STUDIO_LAYOUT_ID = "$_SC_STUDIO_LAYOUT_ID",
  $_SC_STUDIO_PLAYLIST_ID = "$_SC_STUDIO_PLAYLIST_ID",
  $_SC_STUDIO_APP_INSTANCE_ID = "$_SC_STUDIO_APP_INSTANCE_ID",
  $_SC_STUDIO_FILE_ID = "$_SC_STUDIO_FILE_ID",
  $_SC_STUDIO_LINK_ID = "$_SC_STUDIO_LINK_ID",
  $_SC_STUDIO_SITE_ID = "$_SC_STUDIO_SITE_ID",
  $_SC_STUDIO_CONTENT_TYPE = "$_SC_STUDIO_CONTENT_TYPE",
}

export type ResultObject = { [key: string]: string };

export type AllAvailableStudioValues = {
  [STUDIO_VARIABLES.$_SC_STUDIO_ORG_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_SPACE_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_SCREEN_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_CHANNEL_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_LAYOUT_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_PLAYLIST_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_APP_INSTANCE_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_FILE_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_LINK_ID]: string;
  [STUDIO_VARIABLES.$_SC_STUDIO_SITE_ID]: string;
  leafItem: LeafItem;
};

export type LeafItem = {
  [k: string]: {
    [x: string]: string;
  };
};

/**
 * https://www.notion.so/screencloud/Studio-Variables-V1-94b4fd0dc2dd40bb8082bf52c1827319
 */

interface UseGetStudioVariables {
  getValues: (keys: string[], zoneId?: string) => { [key: string]: string }[];
  resolve: (
    payload: Record<string, unknown>,
    zoneId?: string
  ) => Record<string, unknown>;
}

export const useGetStudioVariables = (): UseGetStudioVariables => {
  const prefixVariable = "$_SC_STUDIO_";
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  const org = useSelector<PlayerState, OrganizationState>(
    (state) => state.organization
  );
  const channels = useSelector<PlayerState, ChannelsState>(
    (state) => state.channels
  );

  const leafType = [
    STUDIO_VARIABLES.$_SC_STUDIO_APP_INSTANCE_ID,
    STUDIO_VARIABLES.$_SC_STUDIO_FILE_ID,
    STUDIO_VARIABLES.$_SC_STUDIO_LINK_ID,
    STUDIO_VARIABLES.$_SC_STUDIO_SITE_ID,
    STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE,
  ];

  const allValues: AllAvailableStudioValues = {
    [STUDIO_VARIABLES.$_SC_STUDIO_ORG_ID]: org.id,
    [STUDIO_VARIABLES.$_SC_STUDIO_SPACE_ID]: screen.spaceId ?? "",
    [STUDIO_VARIABLES.$_SC_STUDIO_SCREEN_ID]: screen.id,
    [STUDIO_VARIABLES.$_SC_STUDIO_CHANNEL_ID]:
      screen.activeContentItem?.type === "channel"
        ? screen.activeContentItem.id
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_LAYOUT_ID]:
      screen.activeContentItem?.type === "channel"
        ? channels.byId[screen.activeContentItem?.id].layoutId
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_PLAYLIST_ID]:
      screen.activeContentItem?.type === "playlist"
        ? screen.activeContentItem.id
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_APP_INSTANCE_ID]:
      screen.activeContentItem?.type === "app"
        ? screen.activeContentItem.id
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_FILE_ID]:
      screen.activeContentItem?.type === "file"
        ? screen.activeContentItem.id
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_LINK_ID]:
      screen.activeContentItem?.type === "link"
        ? screen.activeContentItem.id
        : "",
    [STUDIO_VARIABLES.$_SC_STUDIO_SITE_ID]:
      screen.activeContentItem?.type === "site"
        ? screen.activeContentItem.id
        : "",
    leafItem: {},
  };

  const allKeys = [
    ...Object.keys(allValues),
    STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE,
  ];

  if (
    screen.activeContentItem?.type === "playlist" ||
    screen.activeContentItem?.type === "channel"
  ) {
    const leafItem: LeafItem = Object.fromEntries(
      (screen.activeContentItem.activeItems ?? []).map((item) => {
        const itemType =
          item?.contentType === "app"
            ? "APP_INSTANCE"
            : item.contentType.toUpperCase();
        return [
          item.zoneId,
          {
            [`${prefixVariable}${itemType}_ID`]: item.contentId,
            [`${STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE}`]: item.contentType,
          },
        ];
      })
    );

    allValues.leafItem = leafItem;
  }

  const resolve = (
    payload: Record<string, unknown>,
    zoneId?: string
  ): Record<string, unknown> => {
    const newPayload = { ...payload };
    Object.keys(newPayload).forEach((key) => {
      const value = newPayload[key];
      if (typeof value === "string" && allKeys.includes(value)) {
        if (!zoneId) {
          if (
            screen.activeContentItem?.type === "playlist" &&
            !isEmpty(allValues.leafItem) &&
            !isEmpty(allValues.leafItem["root"])
          ) {
            // playlist
            if (leafType.includes(value as STUDIO_VARIABLES)) {
              newPayload[key] = allValues.leafItem["root"][value] ?? "";
            } else {
              newPayload[key] =
                allValues[value as keyof AllAvailableStudioValues];
            }
          } else {
            // directly set content to screen or casting
            if (value === STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE) {
              newPayload[key] = screen.activeContentItem?.type ?? "";
            } else {
              newPayload[key] =
                allValues[value as keyof AllAvailableStudioValues];
            }
          }
        } else if (
          zoneId &&
          !isEmpty(allValues.leafItem) &&
          !isEmpty(allValues.leafItem[zoneId])
        ) {
          // channel
          if (leafType.includes(value as STUDIO_VARIABLES)) {
            newPayload[key] = allValues.leafItem[zoneId][value] ?? "";
          } else {
            newPayload[key] =
              allValues[value as keyof AllAvailableStudioValues];
          }
        }
      }

      if (typeof value === "object") {
        newPayload[key] = resolve(value as Record<string, unknown>, zoneId);
      }
    });
    return newPayload;
  };

  const getValues = (keys: string[], zoneId?: string): ResultObject[] => {
    const result: ResultObject[] = [];
    const allKeys = Object.keys(allValues) as STUDIO_VARIABLES[];
    if (!zoneId) {
      if (
        screen.activeContentItem?.type === "playlist" &&
        !isEmpty(allValues.leafItem) &&
        !isEmpty(allValues.leafItem["root"])
      ) {
        // playlist
        const parentType = allKeys.filter(
          (key: STUDIO_VARIABLES) => !leafType.includes(key)
        );
        const intersectParentKeys = intersection(parentType, keys);
        intersectParentKeys.forEach((key: string) => {
          result.push({
            [key]: allValues[key as keyof AllAvailableStudioValues] as string,
          });
        });

        const intersectLeafKeys = intersection(
          leafType,
          Object.keys(allValues.leafItem["root"])
        );
        intersectLeafKeys.forEach((key) => {
          result.push({
            [key]: allValues.leafItem["root"][key],
          });
        });
        return fillLeftKeys(result, keys);
      } else {
        // directly set content or casting
        const intersectKeys = intersection(allKeys, keys);
        intersectKeys.forEach((key) => {
          result.push({
            [key]: allValues[key as keyof AllAvailableStudioValues] as string,
          });
        });

        if (keys.includes(STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE)) {
          result.push({
            [STUDIO_VARIABLES.$_SC_STUDIO_CONTENT_TYPE]:
              screen.activeContentItem?.type ?? "",
          });
        }

        return fillLeftKeys(result, keys);
      }
    } else if (
      zoneId &&
      !isEmpty(allValues.leafItem) &&
      !isEmpty(allValues.leafItem)
    ) {
      // channel
      const parentType = allKeys.filter(
        (key: STUDIO_VARIABLES) => !leafType.includes(key)
      );
      const intersectParentKeys = intersection(parentType, keys);
      intersectParentKeys.forEach((key) => {
        result.push({
          [key]: allValues[key as keyof AllAvailableStudioValues] as string,
        });
      });

      const intersectLeafKeys = intersection(
        leafType,
        Object.keys(allValues.leafItem[zoneId])
      );
      intersectLeafKeys.forEach((key) => {
        result.push({
          [key]: allValues.leafItem[zoneId][key],
        });
      });
      return fillLeftKeys(result, keys);
    } else {
      return fillLeftKeys(result, keys);
    }
  };

  return {
    getValues,
    resolve,
  };
};

const fillLeftKeys = (
  result: ResultObject[],
  allKeys: string[]
): ResultObject[] => {
  const keyOfResult = result.map((r) => Object.keys(r)[0]);
  const letfKeys = difference(allKeys, keyOfResult).map((key) => ({
    [key]: "",
  }));
  return [...result, ...letfKeys];
};
