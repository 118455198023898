"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var signage_videoplayer_1 = require("@screencloud/signage-videoplayer");
var React = require("react");
var VideoPlayer = /** @class */ (function (_super) {
    __extends(VideoPlayer, _super);
    function VideoPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.setCurrentTime = function (timePositionSec) {
            if (!_this.player) {
                return;
            }
            _this.player.player.currentTime = timePositionSec;
        };
        _this.getCurrentTime = function () {
            if (!_this.player) {
                return null;
            }
            return _this.player.player.currentTime;
        };
        _this.play = function () {
            if (!_this.player) {
                return;
            }
            _this.player.play();
        };
        _this.pause = function () {
            if (!_this.player) {
                return;
            }
            _this.player.pause();
        };
        _this.onCanplay = function (e) {
            if (typeof _this.props.onCanplay === "function") {
                _this.props.onCanplay(e);
            }
        };
        _this.onCanplayThrough = function (e) {
            if (typeof _this.props.onCanplayThrough === "function") {
                _this.props.onCanplayThrough(e);
            }
        };
        _this.onEnded = function (e) {
            if (typeof _this.props.onEnded === "function") {
                _this.props.onEnded(e);
            }
        };
        _this.onError = function (e) {
            if (typeof _this.props.onError === "function") {
                _this.props.onError(e);
            }
        };
        _this.onLoadedMetadata = function (e) {
            if (typeof _this.props.onLoadedMetadata === "function") {
                _this.props.onLoadedMetadata(e);
            }
        };
        _this.onDurationChange = function (e) {
            if (typeof _this.props.onDurationChange === "function") {
                _this.props.onDurationChange(e);
            }
        };
        _this.destroyPlayer = function () {
            if (!_this.player) {
                throw new Error("Player does not exist, can't destroy.");
            }
            _this.player.removeListener("canplay", _this.onCanplay);
            _this.player.removeListener("canplaythrough", _this.onCanplayThrough);
            _this.player.removeListener("ended", _this.onEnded);
            _this.player.removeListener("error", _this.onError);
            _this.player.removeListener("onloadedmetadata", _this.onLoadedMetadata);
            _this.player.removeListener("durationchange", _this.onDurationChange);
            _this.player.destroy();
            _this.player = undefined;
        };
        _this.playbackPositionUpdateEffect = function (newProps, oldProps) {
            if (_this.player
                && newProps.initialPlaybackPositionMs !== undefined
                && newProps.initialPlaybackPositionMs !== (oldProps && oldProps.initialPlaybackPositionMs)) {
                _this.player.seekTo(newProps.initialPlaybackPositionMs / 1000);
            }
        };
        var id = _this.props.id;
        _this.domId = (!id) ? "p_" + _this.genId() : id;
        return _this;
    }
    Object.defineProperty(VideoPlayer.prototype, "playerId", {
        get: function () {
            return this.domId;
        },
        enumerable: true,
        configurable: true
    });
    VideoPlayer.prototype.componentDidMount = function () {
        this.createPlayer(this.props);
        this.playbackPositionUpdateEffect(this.props);
    };
    VideoPlayer.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, src = _a.src, muted = _a.muted, id = _a.id, controls = _a.controls, loop = _a.loop, autoPlay = _a.autoPlay;
        if (this.player) {
            if (src !== prevProps.src) {
                this.createPlayer(this.props);
            }
            if (muted !== prevProps.muted) {
                if (muted) {
                    this.player.mute();
                }
                else {
                    this.player.unmute();
                }
            }
            if (id !== prevProps.id) {
                this.player.player.id = id ? id : "";
            }
            if (controls !== prevProps.controls) {
                this.player.player.controls =
                    controls !== undefined ? controls : VideoPlayer.defaultProps.controls;
            }
            if (loop !== prevProps.loop) {
                this.player.player.loop =
                    loop !== undefined ? loop : VideoPlayer.defaultProps.loop;
            }
            if (autoPlay !== prevProps.autoPlay) {
                this.player.player.autoplay =
                    autoPlay !== undefined ? autoPlay : VideoPlayer.defaultProps.autoPlay;
            }
        }
        this.playbackPositionUpdateEffect(this.props, prevProps);
    };
    VideoPlayer.prototype.componentWillUnmount = function () {
        if (this.player) {
            this.destroyPlayer();
        }
    };
    VideoPlayer.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("style", { id: "video-styles-" + this.playerId }, "\n          #" + this.playerId + " {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            height: 100%;\n            width: 100%;\n            overflow: hidden;\n          }\n\n          #" + this.playerId + " > video {\n            display: block;\n            width: 100%;\n            height: 100%;\n            object-fit: " + (this.props.sizingType === "fill" ? "cover" : "contain") + ";\n          }\n        "),
            React.createElement("div", { id: this.playerId })));
    };
    VideoPlayer.prototype.genId = function () {
        return (Math.random()
            .toString(36)
            .substring(2, 15) +
            Math.random()
                .toString(36)
                .substring(2, 15));
    };
    VideoPlayer.prototype.createPlayer = function (props) {
        var src = props.src, css = props.css, controls = props.controls, poster = props.poster, autoPlay = props.autoPlay, loop = props.loop, muted = props.muted;
        if (this.player) {
            this.destroyPlayer();
        }
        this.player = new signage_videoplayer_1.SignageNextVideoPlayer.Player({
            autoplay: autoPlay,
            container: "#" + this.playerId,
            controls: controls,
            debug: true,
            loop: loop,
            muted: muted,
            poster: poster,
            style: css || {},
        });
        // add all supported event listener
        this.player.on("canplay", this.onCanplay);
        this.player.on("canplaythrough", this.onCanplayThrough);
        this.player.on("ended", this.onEnded);
        this.player.on("error", this.onError);
        this.player.on("loadedmetadata", this.onLoadedMetadata);
        this.player.on("durationchange", this.onDurationChange);
        this.player.load(src);
    };
    VideoPlayer.defaultProps = {
        autoPlay: false,
        controls: false,
        loop: false,
        poster: "",
        preload: true,
    };
    return VideoPlayer;
}(React.Component));
exports.VideoPlayer = VideoPlayer;
exports.default = VideoPlayer;
