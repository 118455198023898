import { useSelector } from "react-redux";
import { PlayerState } from "../../../../../../store/rootReducer";
import { PlaybackState } from "../../../../../../store/playback/types";
import {
  TimelineItem,
  TimelinesState,
} from "../../../../../../store/timelines/types";
import { useMemo } from "react";

export interface FullscreenContent {
  active: FullscreenItemReport | undefined;
  preload: FullscreenItemReport | undefined;
}

export const useFullscreenContent = (): FullscreenContent => {
  const playbackState = useSelector<PlayerState, PlaybackState>(
    (state) => state.playback
  );
  const timelines = useSelector<PlayerState, TimelinesState>(
    (state) => state.timelines
  );

  return useMemo<FullscreenContent>(
    () => getFullScreenItemsReport(playbackState, timelines),
    [playbackState, timelines]
  );
};

type FullscreenItemStatus = "active" | "preload";

export interface FullscreenItemReport {
  timelineId: string;
  item: TimelineItem;
  indexInTimeline: number;
  nextItemStartTimestamp: number | undefined; // time of switch to the next item according to timeline
}

function getFullScreenItemsReport(
  playbackState: PlaybackState,
  timelines: TimelinesState
): FullscreenContent {
  return {
    active: getFullscreenItemReport(playbackState, timelines, "active"),
    preload: getFullscreenItemReport(playbackState, timelines, "preload"),
  };
}

/**
 * Returns a fullscreen content item report of a given status according to current playback state.
 */
function getFullscreenItemReport(
  playbackState: PlaybackState,
  timelines: TimelinesState,
  itemStatus: FullscreenItemStatus
): FullscreenItemReport | undefined {
  const timelinesEntries = Object.entries(playbackState.timelines);
  const previewItem = playbackState.controls.previewItem;

  for (let i = 0; i < timelinesEntries.length; i++) {
    const [timelineId, timelinePlaybackState] = timelinesEntries[i];
    const itemIndexInTimeline: number | undefined =
      timelinePlaybackState[
        itemStatus === "active" ? "activeIndex" : "preloadIndex"
      ];
    if (typeof itemIndexInTimeline !== "number") {
      continue;
    }

    let targetTimelineItem: TimelineItem | undefined =
      timelines.byId[timelineId].items[itemIndexInTimeline];

    const nextTimelneItem: TimelineItem | undefined =
      timelines.byId[timelineId].items[itemIndexInTimeline + 1];

    // give preview item priority when a preview is active
    if (previewItem?.id) {
      targetTimelineItem = previewItem;
    }

    if (targetTimelineItem?.isFullscreen) {
      return {
        timelineId,
        item: targetTimelineItem,
        indexInTimeline: itemIndexInTimeline,
        nextItemStartTimestamp: nextTimelneItem?.startTimestampMs,
      };
    }
  }

  return undefined;
}
