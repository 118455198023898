import cx from "classnames";
import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Mimetype } from "../../../../store/files/types";
import { ContentSizeType } from "../../../../types/content";
import { ElementSize } from "../../../../utils/helpers";
import styles from "./ImageViewer.module.css";
import { Logger } from "../../../../logger/logger";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { ScreenState } from "../../../../store/screen/types";

import { ContentFailureGenericCb } from "../TimelineViewer/types";
const log = new Logger("imageViewer");

interface ImageProps {
  src: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  name: string;
  mimetype?: Mimetype;
  onContentFailure: ContentFailureGenericCb;
  id?: string;
}

interface ImageViewerProps {
  src: string;
  name: string;
  mimetype?: Mimetype;
  urlKey?: string;
  sizeType?: ContentSizeType;
  containerSize: ElementSize;
  isPreload: boolean;
  onContentFailure: ContentFailureGenericCb;
  id?: string;
}

export const Image = (props: ImageProps): ReactElement<ImageProps> | null => {
  let attemptCount = 1;
  const retryLimit = 3;
  const [isPreloaded, setIsPreloaded] = useState<boolean>(false);
  const [reMountKey, setReMountKey] = useState<number | undefined>(
    new Date().getTime()
  );
  const {
    sizeType,
    src,
    name,
    mimetype,
    isPreload,
    onContentFailure,
    id,
  } = props;
  const retryTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    let preloadingElement: HTMLImageElement | null = null;

    if (!isPreloaded && src !== undefined && src !== "") {
      preloadingElement = new window.Image();
      preloadingElement.onload = () => {
        setIsPreloaded(true);
      };
      preloadingElement.onerror = () => {
        // ignore the error for now
        setIsPreloaded(true);
      };
      preloadingElement.src = src;
    }

    return (): void => {
      if (preloadingElement) {
        preloadingElement.src = "";
        preloadingElement.onload = null;
        preloadingElement.onerror = null;
      }
      preloadingElement = null;
    };
  }, [src, isPreloaded]);

  const sizeStyle = sizeType === "fill" ? styles.imageFill : styles.imageFit;
  const screen = useSelector<PlayerState, ScreenState>((state) => state.screen);
  useEffect(() => {
    return () => {
      if (retryTimeout.current !== null) {
        clearTimeout(retryTimeout.current);
        retryTimeout.current = null;
      }
    };
  }, []);

  const onError = useCallback(() => {
    const timeoutMs = attemptCount * 3 * 1000;
    if (retryTimeout.current !== null) {
      clearTimeout(retryTimeout.current);
      retryTimeout.current = null;
    }
    // TODO: replace the second params with look up function for content object from normaliza data (redux)
    log.error({
      message: `Cannot load image ${src}`,
      context: {
        screenId: screen.id,
        name,
        isPreload: props.isPreload,
      },
    });
    if (attemptCount <= retryLimit) {
      retryTimeout.current = setTimeout(() => {
        setReMountKey(new Date().getTime());
        log.info({
          message: `Retry reload image attemptCount[${attemptCount}]${src}`,
          context: {
            screenId: screen.id,
            name,
            isPreload: props.isPreload,
          },
        });
        attemptCount++;
      }, timeoutMs);
    } else {
      log.warn({
        message: `Reach limit! retry reload image ${src}`,
        context: {
          screenId: screen.id,
          name,
        },
        proofOfPlayFlag: true,
      });
      onContentFailure();
    }
  }, [src, attemptCount, name, screen.id, onContentFailure, props.isPreload]);

  useEffect(() => {
    log.info({
      message: `Show Image ${name}`,
      context: {
        id: id,
        screenId: screen.id,
        name,
        contentType: mimetype,
        isPreload,
        src: src,
      },
    });
  }, [src, name, mimetype, isPreload, screen.id, id]);

  return isPreloaded ? (
    <img
      crossOrigin="anonymous"
      className={cx(styles.image, sizeStyle)}
      alt={name}
      src={src}
      onError={onError}
      data-testid="image-loaded"
      key={reMountKey}
    />
  ) : null;
};

export const ImageViewer = memo(
  (props: ImageViewerProps): ReactElement<ImageViewerProps> => {
    return (
      <div
        className={styles.imageContainer}
        data-testid={`image-${props.urlKey}`}
      >
        <Image
          src={props.src}
          name={props.name}
          mimetype={props.mimetype}
          sizeType={props.sizeType}
          containerSize={props.containerSize}
          isPreload={props.isPreload}
          onContentFailure={props.onContentFailure}
          id={props.id}
        />
      </div>
    );
  }
);
ImageViewer.displayName = "ImageViewer";
